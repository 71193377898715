import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AlertComponent} from "../../../../../../common/components/alert/alert.component";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {StorageService} from "../../../../../../common/services/storage.service";
import {
    ItemConfigurationFormComponent,
    ItemConfigurationsListComponent
} from "../../../../common/components/inventory/configurations";
import {MatChipInputEvent} from "@angular/material/chips";
import {BarcodeReaderComponent} from "../../../../../../common/components/barcode-reader/barcode-reader.component";
import {User} from "../../../../../../common/interfaces/user.interface";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HubSelectComponent} from "../../../../../../common/components/form/hub-select/hub-select.component";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {PartMaster} from "../../../../../../common/interfaces/part-master.interface";
import {CommonPartMasterViewComponent} from "../../../../common/components/part-master";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import { ModalComponent } from "src/modules/common/components/modal/modal.component";

@Component({
    template: ""
})
export abstract class AbstractOrderItemComponent implements OnInit, OnDestroy {

    protected state: Base.IState;

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    protected partner_id: number;

    protected allocationId: number;

    protected totalQty: { [key: string]: number } = {};

    protected orderRef: string = null;

    protected hubValidators: any[] = [Validators.required, Validators.min(1)];

    protected selectedBoxesItems: any[] = [];

    public itemType: string;

    public manualType: boolean = false;

    public window_title: string = "Add item";

    public stockMode: FormControl = new FormControl("free");

    public box_is_required: boolean = false;

    public formType: string = null;

    public partner: User.IPartner;

    public rma_send_only: FormControl = new FormControl(false);

    public availableItemsSearch: FormControl = new FormControl(null);

    public availableBoxesSearch: FormControl = new FormControl(null);

    public availablePalletsSearch: FormControl = new FormControl(null);

    public availableLocationsSearch: FormControl = new FormControl(null);

    @ViewChild(AlertComponent, {static: false})
    public alert: AlertComponent;

    @ViewChild(ConfirmComponent, {static: false})
    public confirm: ConfirmComponent;

    @ViewChild("outboundHub", {static: false})
    public outboundHubRef: HubSelectComponent;

    @ViewChild("inboundHub", {static: false})
    public inboundHubRef: HubSelectComponent;

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        outbound_hub_id: new FormControl(null, this.hubValidators),
        inbound_hub_id: new FormControl(null, this.hubValidators),
        part_master_id: new FormControl(null, [Validators.required, Validators.min(1)]),
        ref: new FormControl(null),
        ref2: new FormControl(null),
        allocation_ref: new FormControl(HelpersService.randomString()),
        pallet: new FormControl([]),
        box: new FormControl([]),
        serial: new FormControl([]),
        warehouse_location_id: new FormControl(null),
        batch: new FormControl(null),
        rev: new FormControl(null),
        lot: new FormControl(null),
        price: new FormControl(null),
        configurations: new FormControl(null),
        quantity: new FormControl(null, [Validators.required, Validators.min(1)]),
        status_id: new FormControl(null, [Validators.required, Validators.min(1)]),
    });

    public modal: Modal.IModal;

    public options: any = {
        hubs_from: null,
        hubs_to: null
    };

    public info: any = {
        send_only: false,
        required_serial_inbound: false,
        has_serial: false
    };

    public selectedInventory: {
        pallet: Warehouse.IPallet [],
        box: Warehouse.IBox[],
        serial: Warehouse.IInventory[]
    } = {
        pallet: [],
        box: [],
        serial: []
    };

    public itemStatuses: any[];

    public serials: string[] = [];

    public boxes: string[] = [];

    public disableQuantity: boolean = false;

    public disableBatch: boolean = false;

    public disableRevision: boolean = false;

    public disableLot: boolean = false;

    public originQuantity = 0;

    public totalStock: number;

    public allocated: IPagination<any>;

    public separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public address_id: number = null;

    public disable_hub_select: boolean = false;

    public kit_parts: {
        part_master: PartMaster.IItem,
        quantity: number,
        serial: string
    }[] = null;

    public serialRequired: boolean = false;

    public hideSerialField: boolean = false;

    public onlyInStockHubsForOutbound: FormControl = new FormControl(false);

    public exactSearch: FormControl = new FormControl(false);

    @ViewChild("palletTableRef", {static: false})
    public palletTableRef: Table2Component;

    @ViewChild("boxTableRef", {static: false})
    public boxTableRef: Table2Component;

    @ViewChild("serialTableRef", {static: false})
    public serialTableRef: Table2Component;

    @ViewChild("analogsTableRef", {static: false})
    public analogsTableRef: Table2Component;

    public palletTable: Table.ISettings;
    public boxTable: Table.ISettings;
    public serialTable: Table.ISettings;

    public palletRowsSelected: Warehouse.IPallet[] = [];
    public boxRowsSelected: Warehouse.IBox[] = [];
    public serialRowsSelected: Warehouse.IInventory[] = [];

    public selectedPalletSearch: FormControl = new FormControl(null);
    public selectedBoxesSearch: FormControl = new FormControl(null);
    public selectedSerialsSearch: FormControl = new FormControl(null);

    public analogsTable: Table.ISettings;
    public allocatedTable: Table.ISettings;

    public locations: ISelectOption[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected storageService: StorageService,
        protected spinnerService: SpinnerService
    ) {
    }


    /**
     * Set form values (modal params data)
     * @returns {void}
     */
    protected async setFormValues(): Promise<any> {
        this.address_id = this.modal.params.shipToAddress
            ? this.modal.params.shipToAddress
            : this.modal.params.details
                ? this.modal.params.details.address_id
                : null;


        if (!this.modal.params.data) {
            this.formGroup.get("status_id").setValue(15);
            this.formGroup.get("outbound_hub_id").setValue(this.modal.params.default_outbound_hub_id);
            this.formGroup.get("inbound_hub_id").setValue(this.modal.params.default_inbound_hub_id);
            this.changeDetectorRef.markForCheck();

            return;
        }

        const data: any = this.modal.params.data;

        this.manualType = true;

        this.itemType = data.type;

        if (this.itemType === "outbound") {
            this.formGroup.get("outbound_hub_id").setValue(data.inventory_conversion_id);
        }

        if (this.itemType === "inbound") {
            this.formGroup.get("inbound_hub_id").setValue(data.inventory_conversion_id);
        }

        this.formGroup.get("part_master_id").setValue(data.part_master_id);
        this.formGroup.get("configurations").setValue(data.configurations);
        this.formGroup.get("price").setValue(data.price);
        this.formGroup.get("batch").setValue(data.batch);
        this.formGroup.get("rev").setValue(data.rev);
        this.formGroup.get("lot").setValue(data.lot);
        this.formGroup.get("ref").setValue(data.ref);
        this.formGroup.get("ref2").setValue(data.ref2);
        this.formGroup.get("status_id").setValue(Number(data.status_id));

        for (const pallet of data.stock?.pallet || []) {
            this.addPalletString({value: pallet, input: null, chipInput: null});
        }
        this.getPalletsQuantities();

        for (const box of data.stock?.box || []) {
            this.addBoxString({value: box, input: null, chipInput: null});
        }

        if (this.modal.params.data.stock?.box?.length) {
            for (let i = 0; i < this.modal.params.data.stock.box.length; i += 50) {
                this.getBoxesQuantities(this.modal.params.data.stock.box.slice(i, i + 50));
            }
        }

        for (const serial of data.stock?.serial || []) {
            this.addSerialString({value: serial, input: null, chipInput: null});
        }

        if (!data.stock?.pallet?.length && !data.stock?.box?.length && !data.stock?.serial?.length) {
            const locationId = data?.warehouse_location_id > 0
                ? Number(data.warehouse_location_id)
                : null;
            this.formGroup.get("warehouse_location_id").setValue(locationId);
        }

        this.originQuantity = data.quantity;
        this.formGroup.get("quantity").setValue(data.quantity);

        this.changeDetectorRef.markForCheck();

        this.updateAvailability();
    }

    protected async getPalletsQuantities(): Promise<any> {
        if (!this.modal.params.data.stock?.pallet) {
            return;
        }
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/pallets`, {
            refs: this.modal.params.data.stock.pallet,
            relation_sum: [
                {"Inventories": "quantity"}
            ]
        });

        if (data) {
            for (const pallet of this.selectedInventory.pallet) {
                const stock = data.find((p: Warehouse.IPallet): boolean => {
                    return p.ref === pallet.ref;
                });

                if (stock) {
                    pallet.inventories_count = stock.inventories_count;
                }
            }
            this.setQuantity();
        }
    }

    protected async getBoxesQuantities(refs: string[]): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/boxes`, {
            refs,
            relation_sum: [
                {"Inventories": "quantity"}
            ]
        });

        if (data) {
            for (const box of this.selectedInventory.box) {
                const stock = data.find((p: Warehouse.IBox): boolean => {
                    return p.ref === box.ref;
                });

                if (stock) {
                    box.inventories_count = stock.inventories_count;
                }
            }
            this.setQuantity();
        }
    }

    protected setSerialValidation(): void {

        if (!this.info.has_serial) {
            this.hideSerialField = true;
            this.formGroup.get("serial").clearValidators();
        } else {
            this.hideSerialField = false;

            this.serialRequired = (this.itemType === "inbound" && this.info.required_serial_inbound) ||
                (this.itemType === "outbound" && this.info.required_serial_outbound);

            if (this.serialRequired) {
                this.formGroup.get("serial").setValidators([Validators.required]);
            } else {
                this.formGroup.get("serial").clearValidators();
            }
        }

        this.formGroup.get("serial").updateValueAndValidity();
        this.changeDetectorRef.markForCheck();
    }

    protected async getKitParts(id: number | string): Promise<any> {
        this.spinnerService.show();

        this.kit_parts = null;

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster", "kits", "" + id]);

        this.spinnerService.hide();

        if (data && data.length) {
            this.kit_parts = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get item statuses
     * @returns void
     */
    protected async getItemStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["status"], null, {
            data_structure: "select",
            type: "items",
            property_line_type: this.modal.params.lineType || null
        });
        if (data) {
            this.itemStatuses = data || [];
            if (this.modal.params.data && this.modal.params.data.status_id) {
                this.formGroup.get("status_id").setValue(Number(this.modal.params.data.status_id));
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get order partner
     */
    protected getPartner(): void {
        const partner: User.IPartner = PartnerService.partner;
        if (partner) {
            this.partner_id = partner.id;
            this.partner = partner;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Setup form listeners
     */
    protected prepareFormListeners(): void {

        this.formGroup.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe(async (value: string): Promise<any> => {
                if (value) {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();

                    if (this.itemType === "outbound") {
                        this.updateAvailability();
                    }
                }
            });


        if (this.itemType === "outbound") {

            this.formGroup.get("outbound_hub_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((value: string): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();

                    this.updateAvailability();
                });

            this.formGroup.get("batch").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl());
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl());
                    this.serialTableRef?.reload(this.getAvailableItemsUrl());
                });

            this.formGroup.get("rev").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl());
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl());
                    this.serialTableRef?.reload(this.getAvailableItemsUrl());
                });

            this.formGroup.get("lot").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl());
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl());
                    this.serialTableRef?.reload(this.getAvailableItemsUrl());
                });

            this.formGroup.get("configurations").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl());
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl());
                    this.serialTableRef?.reload(this.getAvailableItemsUrl());
                });

            this.formGroup.get("warehouse_location_id").valueChanges
                .pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((): void => {
                    this.removeAllPallets();
                    this.removeAllBoxes();
                    this.removeAllSerials();
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl());
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl());
                    this.serialTableRef?.reload(this.getAvailableItemsUrl());
                });

            this.availableItemsSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
                .subscribe((value: string): void => {
                    this.serialTableRef?.reload(this.getAvailableItemsUrl(value));
                });

            this.availableBoxesSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
                .subscribe((value: string): void => {
                    this.boxTableRef?.reload(this.getAvailableBoxesUrl(value));
                });

            this.availablePalletsSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
                .subscribe((value: string): void => {
                    this.palletTableRef?.reload(this.getAvailablePalletsUrl(value));
                });
        }
    }

    protected updateAvailability(): void {
        if (this.modal.params.lineType === "inbound") {
            return;
        }

        setTimeout(async (): Promise<any> => {
            if (!this.formGroup.value.part_master_id || !this.formGroup.value.outbound_hub_id) {
                return;
            }

            this.getAvailablePallets();
            this.getAvailableBoxes();
            this.getAvailableItems();
            this.getAvailableLocations();

            this.getAllocatedItems();
            this.getAnalogs();
        }, 10);
    }

    protected setQuantity(): void {
        let sum: number = 0;

        for (const p of this.selectedInventory.pallet) {
            sum += Number(p.inventories_count);
        }
        this.formGroup.get("pallet").setValue(this.selectedInventory.pallet.map(p => p.ref));

        for (const b of this.selectedInventory.box) {
            sum += Number(b.inventories_count);
        }
        this.formGroup.get("box").setValue(this.selectedInventory.box.map(b => b.ref));

        for (const s of this.selectedInventory.serial) {
            sum++;
        }
        this.formGroup.get("serial").setValue(this.selectedInventory.serial.map(s => s.serial));

        this.disableQuantity = sum > 0;

        this.formGroup.get("quantity").setValue(sum > 0 ? sum :
            this.modal.params.data ? this.modal.params.data.quantity : 1);
        this.changeDetectorRef.markForCheck();

        this.palletTableRef?.detectChanges();
        this.boxTableRef?.detectChanges();
        this.serialTableRef?.detectChanges();
    }

    protected async removeSelectedBoxChildEntities(box_id: number): Promise<any> {
        for (const index in this.selectedInventory.serial) {
            if (this.selectedInventory.serial[Number(index)].box_id === box_id) {
                delete this.selectedInventory.serial[Number(index)];
            }
        }
        this.selectedInventory.serial = this.selectedInventory.serial.filter((v) => !!v);
    }

    protected async removeSelectedPalletChildEntities(pallet_id: number): Promise<any> {
        for (const box_index in this.selectedInventory.box) {
            if (this.selectedInventory.box[Number(box_index)].pallet_id === pallet_id) {
                delete this.selectedInventory.box[Number(box_index)];
            }
        }
        this.selectedInventory.box = this.selectedInventory.box.filter((v) => !!v);


        for (const serial_index in this.selectedInventory.serial) {
            if (this.selectedInventory.serial[Number(serial_index)].box?.pallet_id === pallet_id) {
                delete this.selectedInventory.serial[Number(serial_index)];
            }
        }
        this.selectedInventory.serial = this.selectedInventory.serial.filter((v) => !!v);
    }

    protected async getAvailableLocations(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/hubs/${this.formGroup.get(this.itemType + "_hub_id").value}/warehouse-locations`,
            {
                part_master_id: this.formGroup.value.part_master_id,
                batch: this.formGroup.value.batch,
                rev: this.formGroup.value.rev,
                lot: this.formGroup.value.lot,
                configurations: this.formGroup.value.configurations,
                data_structure: "select"
            });

        if (data) {
            this.locations = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    protected getAvailableItemsUrl(search_by: string = null): Table.ITableApi {
        return {
            url: [
                this.modal.params.state.section,
                "inventories"
            ],
            query: {
                hub_id: this.formGroup.get(this.itemType + "_hub_id").value,
                part_master_id: this.formGroup.value.part_master_id,
                batch: this.formGroup.value.batch,
                rev: this.formGroup.value.rev,
                lot: this.formGroup.value.lot,
                configurations: this.formGroup.value.configurations,
                location_id: this.formGroup.value.warehouse_location_id,
                available_only: true,
                relations: [
                    "Box:id,ref,pallet_id",
                    "Box.Pallet:id,ref",
                    "WarehouseLocation:id,location",
                    "SerialDetails.OrderItemModuleNotes",
                ],
                search_by: search_by,
                search_in: [
                    "item",
                    "serial",
                    "Box.ref",
                    "Box.Pallet.ref",
                    "WarehouseLocation.location"
                ]
            },
            version: 3,
        };
    }

    protected getAvailableBoxesUrl(search_by: string = null): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "boxes"
            ],
            query: {
                hub_id: this.formGroup.get(this.itemType + "_hub_id").value,
                part_master_id: this.formGroup.value.part_master_id,
                batch: this.formGroup.value.batch,
                rev: this.formGroup.value.rev,
                lot: this.formGroup.value.lot,
                configurations: this.formGroup.value.configurations,
                warehouse_location_id: this.formGroup.value.warehouse_location_id,
                available_only: true,
                relations: [
                    "Pallet:id,ref",
                    "WarehouseLocation:id,location",
                    "Inventory:id,box_id,item"
                ],
                not_in_pallet: this.formGroup.value.pallet,
                search_by,
                search_in: [
                    "ref",
                    "Pallet.ref",
                    "WarehouseLocation.location"
                ]
            },
            version: 3
        };
    }

    protected getAvailablePalletsUrl(search_by: string = null): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "pallets"
            ],
            query: {
                hub_id: this.formGroup.get(this.itemType + "_hub_id").value,
                part_master_id: this.formGroup.value.part_master_id,
                batch: this.formGroup.value.batch,
                rev: this.formGroup.value.rev,
                lot: this.formGroup.value.lot,
                configurations: this.formGroup.value.configurations,
                location_id: this.formGroup.value.warehouse_location_id,
                available_only: true,
                relations: [
                    "WarehouseLocation:id,location"
                ],
                search_by,
                search_in: [
                    "ref",
                    "WarehouseLocation.location"
                ]
            },
            version: 3
        };
    }


    protected getAnalogsUrl(onlyInStock = false): Table.ITableApi {
        return {
            url: [this.state.section, "part-masters", this.formGroup.value.part_master_id, "analogs"],
            query: {
                in_stock: true,
                is_active: true,
                hub_id: this.formGroup.value.outbound_hub_id
            },
            version: 3
        };
    }


    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onItemSelected($event: any): void {
        if (!$event) {
            return;
        }
        this.formGroup.get("part_master_id").setValue($event);

        this.api3Service.get(this.modal.params.state.section + "/part-masters/" + $event, {
            appends: [
                "is_kit"
            ]
        })
            .then(({data}: Api.IResponse): void => {
                this.info.has_serial = data.serial;
                this.info.required_serial_inbound = data.required_serial_order_creation_inbound;

                if (!data.batch) {
                    this.disableBatch = true;
                }

                if (!data.revision) {
                    this.disableRevision = true;
                }

                if (!data.lot) {
                    this.disableLot = true;
                }

                if (this.itemType === "inbound") {
                    if (this.info.required_serial_inbound) {
                        this.formGroup.get("serial").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("serial").clearValidators();
                    }

                    if (!this.disableBatch && data.required_batch_inbound) {
                        this.formGroup.get("batch").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("batch").clearValidators();
                    }

                    if (!this.disableRevision && data.required_revision_inbound) {
                        this.formGroup.get("rev").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("rev").clearValidators();
                    }
                    if (!this.disableLot && data.required_lot_inbound) {
                        this.formGroup.get("lot").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("lot").clearValidators();
                    }

                } else {
                    if (!this.disableBatch && data.required_batch_outbound) {
                        this.formGroup.get("batch").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("batch").clearValidators();
                    }

                    if (!this.disableRevision && data.required_revision_outbound) {
                        this.formGroup.get("rev").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("rev").clearValidators();
                    }

                    if (!this.disableLot && data.required_lot_outbound) {
                        this.formGroup.get("lot").setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("lot").clearValidators();
                    }
                }

                this.formGroup.get("serial").updateValueAndValidity();
                this.formGroup.get("batch").updateValueAndValidity();
                this.formGroup.get("rev").updateValueAndValidity();
                this.formGroup.get("lot").updateValueAndValidity();

                if (this.modal.params.data?.part_master_id !== data.id || !this.modal.params.data?.price) {
                    const priceValueColumn = this.modal?.params?.serviceLevel?.properties?.invoice_values
                        ?? "declared";
                    this.formGroup.get("price").setValue(data[priceValueColumn + "_value"]);
                }

                this.changeDetectorRef.markForCheck();

                if (data.is_kit) {
                    this.getKitParts(data.id);
                }
            });

    }

    public showItemInfo(): void {
        this.modalService.open(CommonPartMasterViewComponent, {
            part_master_id: this.formGroup.value.part_master_id
        });
    }

    /**
     * Add item configurations
     * @param {string} action
     * @returns {Promise<any>}
     */
    public async addConfiguration(action?: string): Promise<any> {
        let response: Modal.IResponse;
        if (!action || action === "list") {
            response = await this.modalService.open(ItemConfigurationsListComponent, {
                item_id: this.formGroup.get("part_master_id").value
            });
        } else if (action === "form") {
            response = await this.modalService.open(ItemConfigurationFormComponent, {
                item_id: this.formGroup.get("part_master_id").value,
                modalWidth: 650
            });
        }
        if (response) {
            if (response.name === "form") {
                return this.addConfiguration("form");
            } else if (response.name === "list") {
                return this.addConfiguration("list");
            } else if (response.name === "value") {
                this.formGroup.get("configurations").setValue(response.value);
                this.changeDetectorRef.markForCheck();
            }
        }
    }


    public addPalletString(event: MatChipInputEvent): void {
        const value: string = (event.value || "").trim();
        if (!value) {
            return;
        }

        const values: string[] = value.split(/[;,\t\s]+/);

        for (const v of values) {
            if (this.selectedInventory.pallet.find((pallet) => pallet.ref === v)) {
                return;
            }
            this.selectedInventory.pallet.push({
                id: (Math.random() + 1) * 1000,
                ref: v,
                inventories_count: 1
            });
        }

        event.chipInput?.clear();
        this.setQuantity();
    }

    public addBoxString(event: MatChipInputEvent): void {
        const value: string = (event.value || "").trim();
        if (!value) {
            return;
        }

        const values: string[] = value.split(/[;,\t\s]+/);

        for (const v of values) {
            if (this.selectedInventory.box.find((box) => box.ref === v)) {
                return;
            }

            this.selectedInventory.box.push({
                id: (Math.random() + 1) * 1000,
                ref: v,
                inventories_count: 1,
                pallet_id: null,
            });
        }

        event.chipInput?.clear();
        this.setQuantity();
    }

    public addSerialString(event: MatChipInputEvent): void {
        const value: string = (event.value || "").trim();
        if (!value) {
            return;
        }

        const values: string[] = value.split(/[;,\t\s]+/);

        for (const v of values) {
            if (this.selectedInventory.serial.find((inventory) => inventory.serial === v)) {
                return;
            }

            this.selectedInventory.serial.push({
                id: (Math.random() + 1) * 1000,
                serial: v,
                item: null,
                quantity: 1,
                box_id: null,
                inventory_conversion_id: 1,
                part_master_id: 1,
            });
        }

        event.chipInput?.clear();
        this.setQuantity();
    }


    public addPalletFromTable(pallet: any): void {
        if (!pallet.ref) {
            return;
        }
        if (this.selectedInventory.pallet.find((v) => v.id === pallet.id)) {
            return;
        }

        this.selectedInventory.pallet.push(pallet);
        this.removeSelectedPalletChildEntities(pallet.id);
        this.setQuantity();
    }

    public addBoxFromTable(box: Warehouse.IBox): void {
        if (!box?.ref) {
            return;
        }
        if (this.selectedInventory.box.find((v) => v.id === box.id)) {
            return;
        }
        if (box.pallet_id && this.selectedInventory.pallet.find((v) => v.id === box.pallet_id)) {
            return;
        }

        this.selectedInventory.box.push(box);
        this.removeSelectedBoxChildEntities(box.id);
        this.setQuantity();
    }

    public addSerialFromTable(inventory: Warehouse.IInventory): void {
        if (!inventory.serial) {
            return;
        }

        if (this.selectedInventory.serial.find((v) => v.id === inventory.id)) {
            return;
        }
        if (inventory.box_id && this.selectedInventory.box.find((v) => v.id === inventory.box_id)) {
            return;
        }
        if (inventory.pallet_id && this.selectedInventory.pallet.find((v) => v.id === inventory.pallet_id)) {
            return;
        }

        this.selectedInventory.serial.push(inventory);

        this.setQuantity();
    }


    public addMultiplePalletsFromTable(): void {
        for (const pallet of this.palletRowsSelected) {
            this.addPalletFromTable(pallet);
        }

        this.palletRowsSelected = [];
        this.palletTableRef.resetSelection();
    }

    public addMultipleBoxesFromTable(): void {
        for (const box of this.boxRowsSelected) {
            this.addBoxFromTable(box);
        }

        this.boxRowsSelected = [];
        this.boxTableRef.resetSelection();
    }

    public addMultipleSerialsFromTable(): void {
        for (const serial of this.serialRowsSelected) {
            this.addSerialFromTable(serial);
        }

        this.serialRowsSelected = [];
        this.serialTableRef.resetSelection();
    }


    public removeAllPallets(): void {
        this.selectedInventory.pallet = [];
        this.setQuantity();
    }

    public removeAllBoxes(): void {
        this.selectedInventory.box = [];
        this.setQuantity();
    }

    public removeAllSerials(): void {
        this.selectedInventory.serial = [];
        this.setQuantity();
    }


    public removePalletChip(pallet: Warehouse.IPallet): void {
        const index = this.selectedInventory.pallet.findIndex(i => i.id === pallet.id);

        if (index < 0) {
            return;
        }

        this.selectedInventory.pallet.splice(index, 1);

        this.setQuantity();
    }

    public removeBoxChip(box: Warehouse.IBox): void {
        const index = this.selectedInventory.box.findIndex(i => i.id === box.id);

        if (index < 0) {
            return;
        }

        this.selectedInventory.box.splice(index, 1);
        this.setQuantity();
    }

    public removeSerialChip(inventory: Warehouse.IInventory): void {
        const index = this.selectedInventory.serial.findIndex(i => i.id === inventory.id);

        if (index < 0) {
            return;
        }

        this.selectedInventory.serial.splice(index, 1);
        this.setQuantity();
    }


    /**
     * Get already allocated items
     * @returns {Promise<any>}
     */
    public async getAllocatedItems(): Promise<any> {
        this.allocatedTable = {
            api: {
                url: [this.state.section, "inventories", "allocations"],
                query: {
                    hubs: [this.formGroup.value.outbound_hub_id],
                    part_master_id: this.formGroup.value.part_master_id,
                    configurations: this.formGroup.value.configurations,
                    type: "outbound",
                    relations: [
                        "Box:id,ref,pallet_id",
                        "Box.Pallet:id,ref",
                        "WarehouseLocation:id,location",
                        "Order:id,ref"
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "order.ref",
                    name: "Order.ref",
                    title: "Order",
                    sortable: false,
                },
                {
                    data: "item",
                    title: "Item",
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "rev",
                    title: "Revision"
                },
                {
                    data: "lot",
                    title: "Lot"
                },
                {
                    data: "configurations",
                    title: "Config"
                },
                {
                    data: "quantity",
                    title: "Qty",
                    searchable: false
                },
                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box",
                    sortable: false,
                },
                {
                    data: "box.pallet.ref",
                    name: "Box.Pallet.ref",
                    title: "Pallet",
                    sortable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location",
                    sortable: false
                },
            ]
        };
    }

    public async getAnalogs(): Promise<any> {

        this.analogsTable = {
            api: this.getAnalogsUrl(),
            actions: [
                {
                    name: "arrow_back",
                    title: "Select",
                    click: (row: any): void => {
                        this.addAnalogFromTable(row.id);
                    },
                }
            ],
            columns: [
                {
                    data: "item",
                    title: "Item",
                },
                {
                    data: "inventories_sum_quantity",
                    title: "In stock",
                    cssClass: "text-center"
                },
            ],
        };
    }

    public async getAvailableItems(): Promise<any> {

        this.serialTable = {
            api: this.getAvailableItemsUrl(),
            actions: [
                {
                    name: "arrow_back",
                    title: "Add serial",
                    click: (row: any): void => {
                        this.addSerialFromTable(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return this.formGroup.get("serial").value.includes(row.serial)
                            || this.formGroup.get("box").value.includes(row.box?.ref)
                            || this.formGroup.get("pallet").value.includes(row.box?.pallet?.ref);
                    },
                    onOff: (row: any): boolean => {
                        return row.serial;
                    }
                },
            ],
            columns: [
                {
                    data: "item",
                    title: "Item",
                },
                {
                    data: "serial",
                    title: "Serial",
                    render: (row: any): string => {
                        return row.serial_details?.order_item_module_notes?.length
                        ? `<span>${row.serial}
                        <mat-icon class='mat-icon material-icons'>info</mat-icon>
                        </span>`
                        : row.serial ?? "";
                    },
                    click: (row: any): void => {
                        if (row.serial_details?.order_item_module_notes?.length) {
                            this.showList(
                                "Notes",
                                row.serial_details?.order_item_module_notes
                                .map(note => note.content)
                            );
                        };
                    },
                },
                {
                    data: "batch",
                    title: "Batch",
                    render: (row: any): string => {
                        return row.batch ? `<button class="pointer mdc-button mat-mdc-raised-button mat-primary"
                            title="Add batch">${row.batch}</button>` : "";
                    },
                    click: (row: any): void => {
                        this.formGroup.get("batch").setValue(row.batch);
                        this.changeDetectorRef.markForCheck();
                    },
                },
                {
                    data: "rev",
                    title: "Revision",
                    render: (row: any): string => {
                        return row.rev ? `<button class="pointer mdc-button mat-mdc-raised-button mat-primary"
                            title="Add revision">${row.rev}</button>` : "";
                    },
                    click: (row: any): void => {
                        this.formGroup.get("rev").setValue(row.rev);
                        this.changeDetectorRef.markForCheck();
                    },
                },
                {
                    data: "lot",
                    title: "Lot",
                    render: (row: any): string => {
                        return row.lot ? `<button class="pointer mdc-button mat-mdc-raised-button mat-primary"
                            title="Add lot">${row.lot}</button>` : "";
                    },
                    click: (row: any): void => {
                        this.formGroup.get("lot").setValue(row.lot);
                        this.changeDetectorRef.markForCheck();
                    },
                },
                {
                    data: "configurations",
                    title: "Config",
                    render: (row: any): string => {
                        return row.configurations ?
                            `<button class="pointer mdc-button mat-mdc-raised-button mat-primary"
                            title="Add configuration">${row.configurations}</button>` : "";
                    },
                    click: (row: any): void => {
                        this.formGroup.get("configurations").setValue(row.configurations);
                        this.changeDetectorRef.markForCheck();
                    },
                },
                {
                    data: "quantity",
                    title: "Qty",
                    searchable: false
                },
                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box",
                    sortable: false,
                },
                {
                    data: "box.pallet.ref",
                    name: "Box.Pallet.ref",
                    title: "Pallet",
                    sortable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location",
                    sortable: false
                },
            ],
            multi_select: (row: any): boolean => {
                return row.serial
                    && !this.formGroup.get("serial").value.includes(row.serial)
                    && !this.formGroup.get("box").value.includes(row.box?.ref)
                    && !this.formGroup.get("pallet").value.includes(row.box?.pallet?.ref);
            },
        };
    }

    public async getAvailableBoxes(): Promise<any> {
        if (this.partner?.properties?.orders_items_hide_box) {
            return;
        }

        this.boxTable = {
            api: this.getAvailableBoxesUrl(),
            actions: [
                {
                    name: "arrow_back",
                    title: "Select",
                    click: (row: any): void => {
                        this.addBoxFromTable(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return this.formGroup.get("box").value.includes(row.ref)
                            || this.formGroup.get("pallet").value.includes(row.pallet?.ref);
                    }
                }
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref",
                },
                {
                    data: "inventory.item",
                    name: "Inventory.item",
                    title: "Item",
                },
                {
                    data: "inventories_count",
                    title: "Qty",
                    searchable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "pallet.ref",
                    name: "Pallet.ref",
                    title: "Pallet"
                },
            ],
            multi_select: (row: any): boolean => {
                return !this.formGroup.get("box").value.includes(row.ref)
                    && !this.formGroup.get("pallet").value.includes(row.pallet?.ref);
            },
        };
    }

    public async getAvailablePallets(): Promise<any> {
        if (this.partner?.properties?.orders_items_hide_box) {
            return;
        }

        this.palletTable = {
            api: this.getAvailablePalletsUrl(),
            actions: [
                {
                    name: "arrow_back",
                    title: "Select",
                    click: (row: any): void => {
                        this.addPalletFromTable(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return this.formGroup.get("pallet").value.includes(row.ref);
                    }
                }
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref",
                },
                {
                    data: "inventories_count",
                    title: "Qty",
                    searchable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
            ],
            multi_select: (row: any): boolean => {
                return !this.formGroup.get("pallet").value.includes(row.ref);
            },
        };
    }


    public addAnalogFromTable(analog_part_master_id: number): void {
        this.removeAllBoxes();
        this.removeAllPallets();
        this.removeAllSerials();

        this.formGroup.get("part_master_id").setValue(analog_part_master_id);
        this.formGroup.get("configurations").setValue(null);

        this.changeDetectorRef.markForCheck();

        this.toastService.show("Selected item was replaced with analog", "warning");
    }


    public getProperty(name: string, defaultValue: any): any {
        return this.userService.getProperty(name, this.modal.params.state, defaultValue);
    }

    public formReset(): void {
        this.formGroup.get("outbound_hub_id").setValue(null);
        this.formGroup.get("inbound_hub_id").setValue(null);
        this.formGroup.get("part_master_id").setValue(null);
        this.formGroup.get("ref").setValue(null);
        this.formGroup.get("serial").setValue([]);
        this.formGroup.get("box").setValue([]);
        this.formGroup.get("batch").setValue(null);
        this.formGroup.get("rev").setValue(null);
        this.formGroup.get("lot").setValue(null);
        this.formGroup.get("configurations").setValue(null);
        this.formGroup.get("status_id").setValue(null);
        this.formGroup.get("warehouse_location_id").setValue(null);

        this.totalQty = {};
        this.changeDetectorRef.markForCheck();
    }

    public showOutboundHubSelect(): boolean {
        return false;
    }

    public showInboundHubSelect(): boolean {
        return false;
    }

    public async showList(title: string, items: any): Promise<any> {
        await this.modalService.open(ModalComponent, {
            title,
            template: items.join("<br>")
        });
    }

    public ngOnInit(): void {
        this.state = this.modal.params.state;

        if (!this.modal.params.serviceLevel.properties.stock_mode) {
            this.modal.params.serviceLevel.properties.stock_mode = "free";
            this.stockMode.setValue("free");
        }

        this.disable_hub_select = !!this.modal.params.disable_hub_select;

        this.stockMode.setValue(this.modal.params.serviceLevel.properties.stock_mode.toLowerCase());

        this.changeDetectorRef.markForCheck();
        this.getPartner();
        this.prepareFormListeners();

        this.setFormValues();
        // show statuses only when item edit
        if (this.modal.params.data) {
            this.getItemStatuses();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
