import {Courier} from "./courier.interface";
import {Contact} from "./contact.interface";
import {Base} from "./base.interfaces";
import {Warehouse} from "./warehouse.interface";
import {User} from "./user.interface";
import {IPagination} from "../components/pagination/pagination.component";
import {Inventory} from "./Inventory.interface";

export namespace Order {

    import IInventoryAllocation = Warehouse.IInventoryAllocation;

    export interface IOrderData {
        id?: number;
        created_at: string | Date;
        created_by: {
            id: number;
            name: string;
        };
        confirm_on: string;
        confirmed: boolean;
        confirmed_by: {
            id: number;
            name: string;
        };
        courier_service: {
            service_name: string;
            id: number;
            description: string;
        };
        customer_account?: {
            id: number;
            name: string;
            created_at: string | Date;
            updated_at: string | Date;
        };
        customer_account_courier_company?: {
            id: number;
            name: string;
            created_at: string | Date;
            updated_at: string | Date;
        };
        customer_account_service_level?: {
            id: number;
            name: string;
            created_at: string | Date;
            updated_at: string | Date;
        };
        deliver_on?: string;
        incoterms: string;
        in_incoterms: string;
        main_address: Contact.IAddress;
        bill_to_address: Contact.IAddress;
        ref: string;
        ref2: string;
        remarks: string;
        rma: string;
        order_remarks: IRemark[];
        order_remarks_count?: number;
        remarks_count?: number;
        order_attachments_count?: number;
        followups_count?: number;
        out_incoterms: string;
        parent_order_id: number;
        parent_order_ref: string;
        properties: {
            confirmed: boolean,
            auto_confirmation?: boolean
        };
        requestor: Contact.IContact;
        service_level_id: number;
        service_level: Order.IServiceLevel;
        status: {
            name: string,
            id: number
        };
        custom_fields: any;
        warehouse_order?: Warehouse.IOrder[];
        partner: User.IPartner;
        shipments?: Warehouse.IShipment[];
        contacts?: Contact.IContact[];
        main_contacts?: Contact.IContact[];
        main_contact?: Contact.IContact;
        main_bill_contact?: Contact.IContact;
        bill_contacts?: Contact.IContact[];
        warehouse_transactions_no_items?: number;
        has_preconfirm?: boolean;
        holds?: any[];
        service_request?: IServiceRequest;
        outbound_releases_count?: number;
        resellers?: [
            {
                id: number;
                name: string;
                email: string;
            }
        ];
        i_tickets: { [key: string]: string };
        unreleased_outbound_order_items_count?: number;
        order_items_count?: number;
        shipping_instructions?: string;
        inbound_order_item: any;
        orders_address?: Contact.IAddress[];
        priority?: number;
    }

    export interface IServiceLevel {
        id: number;
        name: string;
        coverage: {
            display_name: string;
            id: number;
        };
        description: string;
        order_type: IOrderType;
        shipping_mode: {
            display_name: string;
            id: number;
        };
        courier_service: Courier.IService;
        courier_services: Courier.IService[];
        properties: any;
        team_id: number;
        is_active: boolean;
        addresses?: Contact.IAddress[];
        partner?: User.IPartner;
    }

    export interface IOrderType {
        can_inbound: boolean;
        can_outbound: boolean;
        can_use_defective: boolean;
        can_use_good: boolean;
        description: string;
        display_name: string;
        have_shipment: boolean;
        id: number;
        is_internal: boolean;
        is_return: boolean;
        order_name: string;
        shipment_name: string;
        slug: string;
        steps?: {
            id: number;
            step: number;
            order_type_id: number;
            icon: string;
            name: string;
            description: string;
        }[];
        manual_type_selection: number;
    }

    export interface IOrderTrack {
        id: number;
        order_id: number;
        from_address: Contact.IAddress;
        to_address: Contact.IAddress;
        courier_transactions: Courier.ITransaction[];
    }

    export interface IOrderListParams {
        title: string;
        actions: {
            add: boolean;
            view: boolean;
            delete: boolean;
            export: boolean;
            remarks: boolean;
        };
        query?: {};
        state?: Base.IState;
    }

    export interface IItem {
        id?: number;
        batch: string;
        configurations: string;
        related_configurations?: IItemConfiguration[];
        currency: string;
        item: string;
        quantity: number;
        ref: string;
        serial: string;
        order_id: number;
        rev: string;
        lot?: string;
        order_ref?: string;
        partner_id?: number;
        partner?: User.IPartner;
        order_item?: {
            id: number;
            inventory_conversion?: IInventoryConversion;
        };
        status: {
            id: number;
            name: string;
        };
        warehouse_transactions?: Warehouse.ITransaction[];
        warehouse_transactions_paginated: IPagination<Warehouse.ITransaction>;
        type: string;
        price: number;
        inventory_conversion: IInventoryConversion;
        part_master_id?: number;
        part_master?: Inventory.IPartMaster;
        inventory_allocations?: IInventoryAllocation[];
        warehouse_locations?: Warehouse.ILocation[];
        warehouse_transactions_count?: number;
    }

    export interface IItemConfiguration {
        id: number;
        name: string;
    }

    export interface IInventoryConversion {
        id: number;
        warehouse_slug: string;
        sub_inventory: string;
        customers_inventory_name: string;
        customers_sub_inventory: string;
        gb: string;
        distance: string;
        warehouse?: Warehouse.IWarehouse;
    }

    export interface IRemark {
        id: number;
        partner?: User.IPartner;
        order?: IOrderData;
        owner?: User.IData;
        subject: string;
        message: string;
        updated_at: string;
        order_remark_type_id: number;
    }

    export interface IServiceRequest {
        id: number;
        ref: string;
        notes: string;
    }

    export interface IRemarkType {
        id: number;
        name: string;
    }

    export interface IRemarkClassification {
        id?: number;
        name?: string;
        priority?: number;
        partner_id?: number;
    }

    export const PRIORITY_LEVELS: string[] = [
        "Low",
        "Normal",
        "High",
        "Highest",
    ];
}
