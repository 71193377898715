import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {environment} from "../../../../../../../environments/environment";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {PartMaster} from "../../../../../../common/interfaces/part-master.interface";
import {PartMasterService} from "../../../../../../common/services/part-master.service";
import {Router} from "@angular/router";
import * as moment from "moment/moment";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {takeUntil} from "rxjs/operators";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {User} from "../../../../../../common/interfaces/user.interface";
import {UserService} from "../../../../../../common/services/user.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "common-part-master-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommonPartMasterViewComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public state: Base.IState;

    public modal: Modal.IModal;

    public item: PartMaster.IItem;

    public edit: boolean = true;

    public countries: any[];

    public serial: any;

    public readonly separatorKeysCodes = [ENTER, COMMA] as const;

    public isAvailableProperties = false;

    public formGroup: FormGroup = this.formBuilder.group({
        item: ["", [Validators.required]],
        item_id: [""],
        description: ["", [Validators.required]],
        group: [""],
        ECCN: [""],
        image_path: [""],
        HSC: [""],

        batch: [false],
        required_batch_outbound: [false],
        required_batch_inbound: [false],

        config: [""],
        country: ["", [Validators.required]],
        customs_value: ["", [Validators.required]],
        customs_values_currency: [null, [Validators.required]],
        declared_value: ["", [Validators.required]],
        declared_value_currency: [null, [Validators.required]],
        documents_url: [""],
        manufacturer: [""],
        license_exception_code: [""],
        default_quantity: ["", [Validators.required, Validators.min(1)]],
        family: [""],
        height: ["", [Validators.required]],
        length: ["", [Validators.required]],
        mass_unit: ["kg", [Validators.required]],
        weight: ["", [Validators.required]],
        width: ["", [Validators.required]],

        serial: [false],
        required_serial_wh_outbound: [false],
        required_serial_wh_inbound: [false],
        required_serial_order_creation_outbound: [false],
        required_serial_order_creation_inbound: [false],

        is_virtual: [false],
        is_active: [true],

        lot: [false],
        required_lot_outbound: [false],
        required_lot_inbound: [false],

        revision: [false],
        required_revision_outbound: [false],
        required_revision_inbound: [false],

        contains_battery: [false],
        return_instructions: [false],
        outbound_special_instructions: [null],
        inbound_special_instructions: [null],
        dangerous_goods: [false],
        move_locations_disconnect_boxes: [true],
        days_to_inspection: [0, [Validators.required, Validators.min(0)]],
        days_in_stock: [0, [Validators.required, Validators.min(0)]],
        allocation_type: ["allocate_by_inventory_date", [Validators.required]],
        allocate_pallets: [true],
        allocate_boxes: [true],
        allocate_loose_items: [true],
        uoms: [[1]]
    });

    public notes: FormControl = new FormControl(null);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public can_be_deleted: boolean = false;

    public noteUsage = [
        "Outbound special instructions",
        "Inbound special instructions",
        "Create label content",
        "Private",
    ];

    public conditionalParameters = [
        {
            usage: "Outbound special instructions",
            formControlName: "outbound_special_instructions",
            label: "Outbound special instructions Places",
            type: "multiselect",
            options: [
                /* {
                    id: "pick_list",
                    name: "Pick list"
                }, */
                {
                    id: "order_confirmed_remarks",
                    name: "Order confirmed remarks"
                },
                {
                    id: "order_hold",
                    name: "Order hold",
                },
                /* {
                    id: "afimilk_package_list",
                    name: "Afimilk Package list"
                }, */
                /* {
                    id: "annapurna_commercial_invoice",
                    name: "Annapurna Commencial Invoice"
                }, */
            ]
        },
        {
            usage: "Inbound special instructions",
            formControlName: "inbound_special_instructions",
            label: "Inbound special instructions Places",
            type: "multiselect",
            options: [
                {
                    id: "order_confirmed_remarks",
                    name: "Order confirmed remarks"
                },
                {
                    id: "order_hold",
                    name: "Order hold",
                },
                /* {
                    id: "radware_inspection_required",
                    name: "Radware Inspection Required"
                }, */
            ]
        },
    ];

    public partmasterNotes: any[] = [];

    public serialNoteUsage = [
        "Remarks", "Pick List", "Packing List/Invoice",
        "Label Creation", "Order Item Module"
    ];

    public serialNoteConditionalParameters = [
        {
            usage: "Remarks",
            formControlName: "remark_type",
            label: "Remark Type",
            type: "select",
            options: []
        }
    ];

    public serialNotes: any[] = [];

    public currencies: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private formBuilder: UntypedFormBuilder,
        private helpers: HelpersService,
        private toastService: ToastService,
        private modalService: ModalService,
        private partMasterService: PartMasterService,
        private router: Router,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private apiService: ApiService,
    ) {
    }

    private prepareForm(): void {
        const partner: User.IPartner = PartnerService.partner;
        if (partner) {

            if (this.modal.params.add === true) {
                const set_active = partner.properties.part_master_active_by_default;

                if (set_active) {
                    this.formGroup.get("is_active").setValue(true);
                }
            }

            this.formGroup.addControl("properties", this.formBuilder.group({
                type: [null],
                amazon_pn: [null],
                short_name: [null],
                silicon_family: [null],
                allow_swap_serial_wh_outbound: [null]
            }));
        }
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private async getSerialData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.partMasterService.getSerial(this.modal.params.serial, {
            relations: [
                "WarehouseTransactions.InventoryConversion",
                "Orders.ServiceLevel",
                "Orders.Status",
            ],
            part_master_id: this.modal.params.part_master_id
        });
        this.spinnerService.hide();

        if (data) {
            this.serial = data;
            this.serial.warehouse_transactions.sort((t1: any, t2: any): number => {
                if (moment(t1.created_at).isBefore(t2.created_at)) {
                    return 1;
                } else if (moment(t1.created_at).isAfter(t2.created_at)) {
                    return -1;
                } else {
                    return 0;
                }
            });
            this.getSerialNotes();
        }
        this.changeDetectorRef.markForCheck();

    }

    private async getSerialNotes(): Promise<void> {
        this.spinnerService.show();
        const {data} = await this.partMasterService.getSerialNotes(this.serial._id);
        if (data) {
            this.serialNotes = data;
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();

    }

    private async getCurrencies(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/currencies`, {
            data_structure: "select"
        });
        this.spinnerService.hide();

        if (data) {
            this.currencies = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.serialNoteConditionalParameters[0].options = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get item data
     * @returns {Promise<any>}
     */
    private async getData(): Promise<any> {
        if (this.modal.params.add) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            this.state.section + "/part-masters/" + this.modal.params.part_master_id, {}, {
                relations: [
                    "Analogs:id,item",
                    "PartMasterUoms:id,part_master_id,units",
                    "Notes",
                    "PrivateNotes",
                ],
                exists: [
                    "OrderItems",
                    "InventoryAllocations",
                    "Inventories",
                    "WarehouseTransactions"
                ]
            });

        this.spinnerService.hide();
        if (data) {
            this.item = data;
            this.item.batch = this.item.batch ?? false;
            this.formGroup.patchValue(this.item);
            this.partmasterNotes = this.item.notes;

            this.formGroup.get("uoms").setValue(data.part_master_uoms.map((uom: { units: number }) => {
                return uom.units;
            }));

            this.can_be_deleted = !this.item.inventories_exists && !this.item.order_items_exists
                && !this.item.warehouse_transactions_exists && !this.item.inventory_allocations_exists;

            this.changeDetectorRef.markForCheck();
        }
    }

    public removeUom(uom: number): void {
        const uoms: number[] = this.formGroup.value.uoms || [];
        const index: number = uoms.indexOf(uom);

        uoms.splice(index, 1);

        this.formGroup.get("uoms").setValue(uoms);

        this.changeDetectorRef.markForCheck();
    }

    public addUom(event: MatChipInputEvent): void {
        const value = Number((event.value || "").trim());

        const uoms: number[] = this.formGroup.value.uoms || [];

        if (value) {
            uoms.push(value);
        }

        event.chipInput!.clear();

        this.formGroup.get("uoms").setValue(uoms);
        this.changeDetectorRef.markForCheck();
    }

    public canEdit(): boolean {
        return this.userService.validatePermissions(["edit_part_masters"]);
    }

    /**
     * Upload user image to cloudinary
     */
    public async uploadImage(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            removeDefaultHeaders: true,
            url: "https://api.cloudinary.com/v1_1/" + environment.cloudinary.cloudName + "/upload",
            body: {
                upload_preset: environment.cloudinary.avatar_upload_preset
            },
            fileInputName: "file"
        });

        if (response && response.value && response.value.secure_url) {
            this.formGroup.get("image_path").setValue(response.value.secure_url);
            this.changeDetectorRef.markForCheck();
        }
    }

    public async deleteItem(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Part Master?")) {
            return;
        }
        const response: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            this.state.section + "/part-masters/" + this.modal.params.part_master_id
        );
        if (response.type === "success") {
            this.modal.response.emit(null);
            this.toastService.show(response.message, "success");
            this.changeDetectorRef.markForCheck();
        }

    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("country").setValue($event.value);
    }

    /**
     * Handler for form submit
     */
    public async submitForm(): Promise<any> {
        if (!this.canEdit()) {
            return;
        }

        this.spinnerService.show();
        if (this.modal.params.add === false) {
            const {message, code}: Api.IResponse = await this.partMasterService
                .updatePartMaster(this.item.id, this.formGroup.value);

            if (code === 200) {
                this.toastService.show(message, "success");
                this.getData();
                this.edit = false;
            }
        } else {
            const {message, code}: Api.IResponse = await this.partMasterService.createPartMaster(this.formGroup.value);

            if (code === 200) {
                this.toastService.show(message, "success");
                this.edit = false;
                this.modal.response.emit({
                    name: "closed",
                    value: true
                });
            }

        }
        this.spinnerService.hide();
    }

    public async onSaveSerialNote(note: any): Promise<any> {
        this.spinnerService.show();
        const noteId = note.id;
        if (noteId) {
            await this.partMasterService.updateSerialNote(
                this.serial._id, noteId, note);
        } else {
            await this.partMasterService.createSerialNote(
                this.serial._id,
                {
                    notes: [note]
                }
            );
        }
        const {data} = await this.partMasterService.getSerialNotes(this.serial._id);
        if (data) {
            this.serialNotes = data;
        }
        this.spinnerService.hide();
    }

    public goToOrder(id: number): void {
        this.modal.response.emit({
            name: "closed",
            value: true
        });

        this.router.navigate([
            this.modal.params.state.section,
            "orders",
            "view",
            "id",
            id
        ]);
    }

    public async showAnotherItem(id: number): Promise<any> {
        this.modal.response.emit({
            name: "closed",
            value: true
        });

        await this.modalService.open(CommonPartMasterViewComponent, {
            part_master_id: id,
            add: false,
            modalWidth: 900
        });

    }

    public async onSaveNote(note: any): Promise<void> {
        const part_master_id = this.modal.params.part_master_id;
        this.spinnerService.show();
        const noteId = note.id;
        if (noteId) {
            await this.partMasterService.updatePartMasterNote(
                part_master_id, noteId, note);
        } else {
            await this.partMasterService.createPartMasterNote(
                part_master_id,
                {
                    notes: [note]
                }
            );
        }
        const {data} = await this.partMasterService.getPartMasterNotes(part_master_id);
        if (data) {
            this.partmasterNotes = data;
        }
        this.spinnerService.hide();
    }

    public async onDeleteNote(note: any): Promise<void> {
        const part_master_id = this.modal.params.part_master_id;
        const noteId = note.id;
        if (noteId) {
            this.spinnerService.show();
            await this.partMasterService.deletePartMasterNote(part_master_id, noteId);
            this.spinnerService.hide();
        }
        this.changeDetectorRef.markForCheck();
    }

    public async onDeleteSerialNote(note: any): Promise<void> {
        const noteId = note.id;
        if (noteId) {
            this.spinnerService.show();
            await this.partMasterService.deleteSerialNote(this.serial._id, noteId);
            this.spinnerService.hide();
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {

        this.state = AppStateService.getState();

        this.getCurrencies();

        this.prepareForm();

        this.getData();

        this.prepareCountriesList();

        this.getRemarkTypes();

        if (this.modal.params.serial) {
            this.getSerialData();
        }

        this.formGroup.controls["serial"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.formGroup.controls["required_serial_wh_outbound"].setValue(value);
            this.formGroup.controls["required_serial_wh_inbound"].setValue(value);
            this.formGroup.controls["required_serial_order_creation_outbound"].setValue(value);
            this.formGroup.controls["required_serial_order_creation_inbound"].setValue(value);
        });

        this.formGroup.controls["batch"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.formGroup.controls["required_batch_outbound"].setValue(value);
            this.formGroup.controls["required_batch_inbound"].setValue(value);
        });

        this.formGroup.controls["revision"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.formGroup.controls["required_revision_outbound"].setValue(value);
            this.formGroup.controls["required_revision_inbound"].setValue(value);
        });

        this.formGroup.controls["lot"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.formGroup.controls["required_lot_outbound"].setValue(value);
            this.formGroup.controls["required_lot_inbound"].setValue(value);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
