import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
    OrderActivitiesComponent,
    OrderAttachmentsListComponent,
    OrderCancelModalComponent,
    OrderEditAddressModalComponent,
    OrderShipmentsComponent,
} from "../index";
import {ChangeStatusFormComponent} from "../../../../../../common/components/status/status.component";
import {Router, UrlTree} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Push} from "../../../../../../common/interfaces/push.interface";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {PusherService} from "../../../../../../common/services/pusher.service";
import {Location} from "@angular/common";
import * as moment from "moment";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Observable, Observer} from "rxjs";
import {Request} from "../../../../../../common/interfaces/request.interface";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {OrderService} from "../../../../../../common/services/order.service";
import {FollowupFormComponent} from "../../../../common/components/followup";
import {PartnerOrderService} from "../../../services/order.service";
import {RemarksSidebarComponent} from "../../../../common/components/order";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {StorageService} from "../../../../../../common/services/storage.service";
import {CustomFieldsEditModalComponent}
    from "../../../../common/components/custom-fields/edit-modal/edit-modal.component";

@Component({
    selector: "section-order-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("openClose", [
            state("open", style({
                height: "*",
                opacity: 1,
            })),
            state("closed", style({
                height: 0,
                opacity: 0,
            })),
            transition("open => closed", [
                animate("0.3s")
            ]),
            transition("closed => open", [
                animate("0.3s")
            ]),
        ]),
    ]
})
export class OrderViewComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private job_id: string;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("orderRef", {static: false})
    public orderRef: ElementRef;

    @ViewChild("sidebar", {static: true})
    public sidebarRef: RemarksSidebarComponent;

    @ViewChild("activitiesComponent", {static: false})
    public activitiesRef: OrderActivitiesComponent;

    @ViewChild(OrderShipmentsComponent, {static: false})
    public orderShipmentsComponentRef: OrderShipmentsComponent;

    public canEditServiceLevel: boolean = false;

    public canEditServiceLevelOnConfirmed: boolean = false;

    public editSL: boolean = false;

    public remarksCount: number = 0;
    public attachmentsCount: number = 0;

    /**
     * In no data of order
     * @type {boolean}
     */
    public isNoData: boolean;

    /**
     * Order data
     * @type {Order.IOrderData}
     */
    public orderData: Order.IOrderData;

    /**
     * Order service levels list
     * @type {IServiceLevel[]}
     */
    public orderServiceLevels: Order.IServiceLevel[];

    public courierServices: any[] = [];
    public courierService: any;

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        status: new FormControl(null, [Validators.required]),
        service_level: new FormControl(null, [Validators.required])
    });

    public courier_service: FormControl = new FormControl(null);

    public readonly state: Base.IState;

    /**
     * Order actions toggle
     * @type {{needConfirm: boolean, remarks: boolean}}
     */
    public orderActions: any = {
        needConfirm: false,
        remarks: true,
        cnahgeStatus: false
    };

    public canFindBySlug: boolean = false;
    public foundBySlug: boolean = false;

    public showShipmentsTab: boolean = true;
    public transferDir: {
        inbound: { name: string, type: string, warehouse_slug: string, hub_id: string },
        outbound: { name: string, type: string, warehouse_slug: string, hub_id: string }
    };

    public orderRef2EditState: boolean = false;
    public orderRef2EditFormControl: FormControl = new FormControl(null);

    public orderRefEditState: boolean = false;
    public orderRefEditFormControl: FormControl = new FormControl(null);

    public orderDeliverOnEditState: boolean = false;
    public orderDeliverOnEditFormControl: FormControl = new FormControl(null);

    public orderIncotermsEditState: boolean = false;
    public orderIncotermsEditFormControl: FormControl = new FormControl(null);

    public orderOutIncotermsEditState: boolean = false;
    public orderOutIncotermsEditFormControl: FormControl = new FormControl(null);

    public orderInIncotermsEditState: boolean = false;
    public orderInIncotermsEditFormControl: FormControl = new FormControl(null);

    public orderRemarksEditState: boolean = false;
    public orderRemarksEditFormControl: FormControl = new FormControl(null);

    public orderRmaEditState: boolean = false;
    public orderRmaEditFormControl: FormControl = new FormControl(null);

    public orderSrEditState: boolean = false;
    public orderSrEditFormControl: FormControl = new FormControl(null);

    public warehouseLocalTime: Observable<string>;

    public canConfirm: boolean = true;

    public editReseller: boolean = false;
    public newReseller: string = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private router: Router,
        private pusherService: PusherService,
        private location: Location,
        public userService: UserService,
        public orderService: OrderService,
        private spinnerService: SpinnerService,
        private partnerOrderService: PartnerOrderService,
        public storageService: StorageService,
    ) {
    }

    private onOrderOpen(): void {
        if (this.orderData) {
            this.title.setTitle(this.orderData.ref + " - " + this.title.getTitle());

            if (this.canEditServiceLevel) {
                this.getOrderServiceLevels();
            }
            if (this.orderData.service_level.order_type.slug === "transfer") {
                this.getItems();
            }

            if (!this.orderData.confirmed) {
                this.getOrderWarehouses();
            }

            if (this.state.params.show === "remarks") {
                this.showSidebar(true);
            }
        }
    }

    /**
     * Get order service levels
     * @returns {Promise<any>}
     */
    private async getOrderServiceLevels(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/service-levels`);
        if (data) {
            this.orderServiceLevels = data || [];
            this.formGroup.get("service_level").setValue(this.orderData.service_level.id, {emitEvent: false});
            if (this.orderData && (!this.orderData.confirmed || this.canEditServiceLevelOnConfirmed)) {
                this.getCourierServices();
            }
        }
    }

    /**
     * Get order remarks count
     */
    private async getRemarksCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/remarks`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.remarksCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getAttachmentsCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/attachments`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.attachmentsCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Handle order service level change
     * @returns Promise<any>
     */
    private async setOrderServiceLevel(value: number): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["order", "" + this.orderData.id, "service_levels"],
            {
                service_level_id: value
            });
        if (response) {
            this.toastService.show(response.message, <string>response.type);
            this.getOrderData();
        }
        this.spinnerService.hide();
    }

    private async getCourierServices(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/couriers-services`);
        if (data) {
            this.courierServices = data;
            for (const serv of this.courierServices) {
                if (serv.selected) {
                    this.courier_service.setValue(serv.id, {emitEvent: false});
                    break;
                }
            }
            if (this.orderData.courier_service) {
                this.courier_service.setValue(this.orderData.courier_service.id, {emitEvent: false});
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async setCourierService(id: number): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["courier_service", "order", this.orderData.ref], {
                courier_service_id: id
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getOrderData().then(() => {
                this.orderShipmentsComponentRef.getOrderShipments();
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Get Warehouse types for transfer order
     * @param slug
     * @param type
     */
    private async getWarehouseType(slug: string, type: "inbound" | "outbound"): Promise<any> {
        if (!slug) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", slug]);
        if (data) {
            this.transferDir[type].type = data.type;
        }
        this.spinnerService.hide();
    }


    /**
     * Get order warehouses
     * @returns {Promise<any>}
     */
    private async getOrderWarehouses(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/warehouses`, {}, {
                type: "outbound"
            });
        if (data && data.length) {
            this.warehouseLocalTime = new Observable((observer: Observer<string>): void => {
                if (data[0].timezone) {
                    const interval: any = setInterval((): void => {
                        try {
                            let aestTime: any = new Date()
                                .toLocaleString("en-US", {timeZone: data[0].timezone});
                            aestTime = new Date(aestTime);
                            observer.next(aestTime.toLocaleString());
                        } catch (e) {
                            observer.next(e.message);
                            observer.complete();
                            clearInterval(interval);
                        }
                    }, 1000);
                } else {
                    observer.next(null);
                }
            });
        }
    }

    public async updatePriority(): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                name: "Select priority level",
                description: "",
                fields: [
                    {
                        label: "Priority",
                        name: "priority",
                        size: "full",
                        type: "select",
                        required: true,
                        values: [
                            "Low",
                            "Normal",
                            "High",
                            "Highest"
                        ]
                    },
                ],

            },
            values: {
                priority: Order.PRIORITY_LEVELS[this.orderData.priority]
            },
        });

        if (res?.value) {
            const {code, message}: Api.IResponse = await this.api3Service.put(
                `${this.state.section}/orders/${this.orderData.id}`,
                {
                    priority: Order.PRIORITY_LEVELS.indexOf(res.value.priority)
                }
            );

            if (code === 200) {
                this.getOrderData();
            }
        }
    }

    /**
     * Get order data
     * @returns {Promise<any>}
     */
    public async getOrderData(): Promise<any> {
        if (!this.state.params.id) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.state.params.id], {}, {
                relations: [
                    "BillContacts",
                    "MainContact",
                    "MainContacts",
                    "MainBillContact",
                    "partner",
                    "MainAddress.CountryDetailed",
                    "BillToAddress",
                    "MainAddress.MainNotes",
                    "CustomerAccount",
                    "CustomerAccountCourierCompany",
                    "CustomerAccountServiceLevel",
                    "status",
                    "CourierService",
                    "ServiceLevel.coverage",
                    "ServiceLevel.orderType",
                    "ServiceLevel.shippingMode",
                    "ServiceLevel.courierService",
                    "ConfirmedBy:id,name",
                    "CreatedBy:id,name",
                    "Holds",
                    "ServiceRequest",
                    "Requestor",
                    "Resellers"
                ],
                with_count: [
                    "Followups",
                    "UnreleasedOutboundOrderItems",
                    "OrderItems"
                ]
            });

        this.spinnerService.hide();

        if (data && data.service_level) {
            this.courierService = data.service_level.courierservice || null;
        } else {
            this.courierService = null;
        }

        // transform custom fields object into iterable array
        if (data && data.custom_fields && Object.keys(data.custom_fields).length) {
            Object.keys(data.custom_fields).forEach((key: string, index: number): void => {
                // key: the name of the object key
                // index: the ordinal position of the key within the object
                data.custom_fields[index] = {
                    key,
                    value: data.custom_fields[key]
                };
                delete data.custom_fields[key];
            });
            data.custom_fields = Object.values(data.custom_fields);
        }

        if (data) {
            this.orderOutIncotermsEditFormControl.setValue(data.out_incoterms);
            this.orderInIncotermsEditFormControl.setValue(data.in_incoterms);
        }

        this.orderData = data;

        this.isNoData = !this.orderData;

        if (this.isNoData) {
            return;
        }

        this.orderActions.needConfirm = !this.orderData.confirmed;
        this.formGroup.get("status").setValue(this.orderData.status.id);

        this.canEditServiceLevel = this.userService.validatePermissions(["edit_service_levels"]);
        this.canEditServiceLevelOnConfirmed =
            this.userService.validatePermissions(["edit_service_levels_on_confirmed"]);

        this.orderRef2EditFormControl.setValue(this.orderData.ref2);
        this.orderRefEditFormControl.setValue(this.orderData.ref);
        this.orderDeliverOnEditFormControl.setValue(this.orderData.deliver_on);
        this.orderIncotermsEditFormControl.setValue(this.orderData.incoterms);
        this.orderRemarksEditFormControl.setValue(this.orderData.remarks);
        this.orderSrEditFormControl
            .setValue(this.orderData.service_request ? this.orderData.service_request.ref : null);


        this.showShipmentsTab = true;
        this.changeDetectorRef.markForCheck();
    }

    public customFieldKeyName(key: string): string {
        key = key.replace(/_/g, " ");

        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    public underscoreToSpace(word: string): string {
        return word.replace(/_/g, " ");
    }

    /**
     * Open modal to change order status
     * @returns {Promise<any>}
     */
    public async changeStatus(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ChangeStatusFormComponent, {
            id: this.orderData.id,
            type: "order",
            status: this.orderData.status.id,
            modalWidth: 450
        });
        if (response) {
            this.getOrderData();
        }
    }

    /**
     * Rebase order & items statuses
     * @returns {Promise<any>}
     */
    public async rebaseStatus(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + this.orderData.id, "rebase_statuses"]);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public courierDisabled(courier: any): boolean {
        return courier.service_name !== "NFO" && (
            (this.orderData.deliver_on && (!courier.eta || this.dateBigger(courier.eta, this.orderData.deliver_on)))
            || ((courier.service_name === "RUSH" || courier.service_name === "SCHD") && courier.distance_value_meter
                && courier.distance_value_meter >= 350000)
        );
    }

    /**
     * Check for available order actions
     * @returns {boolean}
     */
    public showActionsMenu(): boolean {
        for (const action in this.orderActions) {
            if (this.orderActions[action]) {
                return true;
            }
        }
        return false;
    }

    public async futureConfirm(): Promise<any> {
        const {value}: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Set confirm date",
                description: "",
                fields: [
                    {
                        label: "Time will be set as GMT+2 (Asia/Jerusalem)",
                        name: "label_1",
                        size: "full",
                        type: "label",
                    },
                    {
                        label: "Confirm date",
                        name: "confirm_on",
                        size: "full",
                        type: "date",
                        required: true,
                    }
                ]
            },
            submitUrl: ["order", this.orderData.id],
            method: Api.EMethod.Put
        });

        if (value && value.code && value.code === 200) {
            this.getOrderData();
        }
    }

    public async silentConfirm(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to silently confirm current order?`)) {
            this.spinnerService.show();
            const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["order", "" + this.orderData.id, "silent_confirm"]);
            this.spinnerService.hide();

            if (code === 200) {
                this.toastService.show(message, "success");
            }
            this.getOrderData().then((): void => {
                this.onOrderOpen();
            });
        }
    }


    /**
     * Confirm order
     * @returns {Promise<any>}
     */
    public async confirmOrder(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to confirm current order?`)) {

            this.orderActions.needConfirm = false;
            this.changeDetectorRef.markForCheck();

            let body: any = {};
            if (this.orderData.has_preconfirm) {
                this.spinnerService.show();

                const preConfingFromConfig: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                    ["order", "" + this.orderData.id, "pre_confirm"]);

                this.spinnerService.hide();
                if (preConfingFromConfig.data && Array.isArray(preConfingFromConfig.data)
                    && preConfingFromConfig.data.includes("Order closed")) {
                    this.toastService.show("Order closed", "success");
                    return this.getOrderData().then((): void => {
                        this.onOrderOpen();
                    });
                }

                if (preConfingFromConfig.data && preConfingFromConfig.data.fields) {

                    const preConfirmResult: Modal.IResponse = await this.modalService.open(CommonFormComponent,
                        {
                            formConfig: preConfingFromConfig.data
                        });

                    if (preConfirmResult && preConfirmResult.value) {
                        body = preConfirmResult.value;
                    } else if (!preConfirmResult) {
                        this.toastService.show("Confirmation cancelled", "error");
                        return;
                    }
                }
            }
            // TODO: Temporary commented
            // if (!this.orderData.deliver_on) {
            //     await this.modalService.open(CommonFormComponent,
            //         {
            //             formConfig: {
            //                 id: 0,
            //                 name: "Fill expected delivery date if needed",
            //                 fields: [
            //                     {
            //                         label: "Expected delivery date",
            //                         name: "deliver_on",
            //                         size: "full",
            //                         type: "date",
            //                         required: false
            //                     }
            //                 ]
            //             },
            //             submitUrl: ["order", this.orderData.ref],
            //             method: Api.EMethod.Put
            //         });
            // }

            this.spinnerService.show();

            this.apiService.setHeaders({"async": this.job_id});
            this.apiService.setHeaders({"async-url": this.location.path()});

            const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["order", "" + this.orderData.id, "confirm"], body);


            this.spinnerService.hide();
            if (code === 200) {
                this.toastService.show(message, "success");
            }

            this.getOrderData().then((): void => {
                this.onOrderOpen();
            });
        }
    }

    /**
     * Counter expired event handler
     */
    public onCounterExpired(): void {
        this.getOrderData().then((): void => {
            this.onOrderOpen();
        });
    }


    /**
     * Edit order address
     * @returns {Promise<any>}
     */
    public async editAddress(): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(OrderEditAddressModalComponent, {
            state: this.state,

            addresses: [this.orderData.main_address, this.orderData.bill_to_address],
            orderData: this.orderData
        });

        if (response) {
            this.getOrderData();
        }
    }

    /**
     * Hide an additional contact
     * @param id
     */
    public async hideContact(id: number): Promise<any> {
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["contact", "" + id], {
                is_visible: 0
            });

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getOrderData();
        }
    }

    /**
     * Copy order ref to clipboard
     */
    public copyRef(): void {
        this.orderRef.nativeElement.select();
        document.execCommand("Copy");
        this.toastService.show("Copied to clipboard", "success");
    }

    /**
     * Show remarks in sidebar
     * @param {boolean} force
     */
    public showSidebar(force: boolean = false): void {
        this.sidebarRef.show(this.orderData.id, this.orderData.ref, force);
    }

    public number(data: any): number {
        return Number(data);
    }

    public async getItems(): Promise<any> {
        this.canConfirm = true;
        const {data}: Api.IResponse = await this.orderService.getItems(this.orderData.ref);

        if (data) {
            this.transferDir = {
                inbound: {name: null, type: "warehouse", warehouse_slug: null, hub_id: null},
                outbound: {name: null, type: "warehouse", warehouse_slug: null, hub_id: null}
            };
            for (const item of data) {
                this.transferDir[item.type].name = item.inventory_conversion.customers_inventory_name;
                this.transferDir[item.type].warehouse_slug = item.inventory_conversion.warehouse_slug;
                this.transferDir[item.type].hub_id = item.inventory_conversion.id;

                if (item.type === "outbound"
                    && item.inventory_conversion.properties
                    && item.inventory_conversion.properties.special_confirmation
                    && !this.userService.validatePermissions("allow_special_confirmation")) {
                    this.canConfirm = false;
                }
            }
            this.changeDetectorRef.markForCheck();
        }

    }


    public dateToHumanTime(date: string): string {
        return moment(date).format("DD.MM.YYYY HH:mm");
    }

    public isAfter(value: string): boolean {
        return value.indexOf("after") > -1;
    }

    public async releaseConfirmation(): Promise<any> {
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["order", "" + this.orderData.id, "remove_confirm"]);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getOrderData().then((): void => {
                this.onOrderOpen();
            });
        }
    }

    public goToParent(): void {
        const url: UrlTree = this.router.createUrlTree([
            this.state.section,
            "orders",
            "view",
            "id",
            this.orderData.parent_order_id,
            "type",
            this.orderData.service_level.order_type.slug
        ]);
        window.open(url.toString(), "_blank");
    }

    public async showAttachments(): Promise<any> {
        await this.modalService.open(OrderAttachmentsListComponent, {
            orderRef: this.orderData.ref,
            order_id: this.orderData.id,
            state: this.state,
            modalWidth: "95%"
        });

        this.getOrderData();
    }

    public toNumber(value: string | number): number {
        return Number(value);
    }

    /**
     * Update order
     * @returns {Promise<any>}
     */
    public async updateOrder(body: any): Promise<any> {
        if (!this.orderData.id) {
            return;
        }

        if (body.out_incoterms === "none") {
            body.out_incoterms = null;
        }

        if (body.in_incoterms === "none") {
            body.in_incoterms = null;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["order", "" + this.orderData.id], body);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getOrderData();
        }
    }

    public async cancelOrder(softCancel: boolean = false): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderCancelModalComponent, {
            orderData: this.orderData,
            softCancel,
            state: this.state
        });

        if (response && response.value) {
            this.router.navigate([this.state.section, this.state.component]);
        }
    }

    public async releaseDocuments(): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + this.orderData.id, "release_documents"], {}, {});

        if (type as string === "success") {
            this.toastService.show(message, "success");
        }
        this.spinnerService.hide();
    }

    public async cloneOrder(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to clone current order?`)) {
            this.spinnerService.show();
            const {data, message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["order", "" + this.orderData.id, "clone"], {}, {});

            if (type as string === "success") {
                this.toastService.show(message, "success");
                if (data && data.id) {
                    const url: UrlTree = this.router.createUrlTree([
                        this.state.section,
                        "orders",
                        "view",
                        "id",
                        data.id,
                        "type",
                        this.orderData.service_level.order_type.slug
                    ]);
                    window.open(url.toString(), "_blank");
                }
            }
            this.spinnerService.hide();
        }
    }

    public async releaseOutbound(): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.orderData.ref, "release_outbound"], {}, {});

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getOrderData();
        }
        this.spinnerService.hide();
    }

    public dateBigger(date: string, compare_to_date: string): boolean {
        return moment(date).isAfter(compare_to_date);
    }

    public async openFollowupForm(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FollowupFormComponent, {
            title: "Followup order " + this.orderData.ref
        });

        if (response && response.value) {
            const followup: Request.IData = response.value;
            this.router.navigate([
                this.state.section,
                "followups",
                "view",
                "id",
                followup.id
            ]);
        }
    }

    public goToFollowups(): void {
        this.router.navigate([
            this.state.section,
            "followups-all",
            "search",
            "order",
            this.orderData.id
        ]);
    }

    public onItemsUpdate(): void {
        this.rebaseStatus().then((): void => {
            this.getOrderData().then((): void => {
                if (this.orderData && this.activitiesRef) {
                    this.activitiesRef.getData();
                }
            });
        });
    }

    public adjustTransactions(): void {
        this.router.navigate([
            this.state.section,
            "adjust-transactions",
            "view",
            "id",
            this.orderData.id
        ]);
    }


    public ngOnInit(): void {
        if (this.state.params.ref && !this.state.params.id) {
            this.state.params.id = this.state.params.ref;
        }
        this.getOrderData().then((): void => {
            this.onOrderOpen();
            this.getRemarksCount();
            this.getAttachmentsCount();

        });
        this.orderActions.cnahgeStatus = this.userService.validatePermissions(["edit_statuses"]);

        this.job_id = HelpersService.randomString();

        this.pusherService.event.pipe(takeUntil(this.destroy$)).subscribe((data: Push.IData): void => {
            if (data.job_id === this.job_id) {
                this.getOrderData();
            }
        });

        this.orderDeliverOnEditFormControl.valueChanges.pipe(
            takeUntil(this.destroy$),
            debounceTime(500), distinctUntilChanged()
        ).subscribe((value: any): void => {
            if (typeof value !== "string") {
                this.orderDeliverOnEditFormControl.setValue(value ? value.format("YYYY-MM-DD HH:mm:ss") : null);
            }
        });

        this.formGroup.get("service_level").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                this.setOrderServiceLevel(value);
            });

        this.courier_service.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                this.setCourierService(value);
            });
    }

    public onCanFindBySlug(can: boolean): void {
        if (this.orderData.service_level.order_type.slug !== "transfer") {
            return;
        }
        this.canFindBySlug = can;
        if (this.foundBySlug !== true && this.transferDir) {
            this.getWarehouseType(this.transferDir["outbound"].warehouse_slug, "outbound");
            this.getWarehouseType(this.transferDir["inbound"].warehouse_slug, "inbound");
        }
        this.foundBySlug = true;
    }

    /**
     * Open modal for custom fields edit
     */
    public async editCustomFields(): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CustomFieldsEditModalComponent, {
            custom_fields: this.orderData.custom_fields,
            window_title: "Edit fields"
        });

        if (res && res.value) {
            const {code, message}: Api.IResponse = await this.orderService
                .updateOrder(this.orderData.id, {custom_fields: res.value});
            if (code === 200) {
                this.toastService.show(message, "success");
                this.getOrderData();
            }
        }
    }

    public async toggleAutoConfirmation(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.partnerOrderService.toggleAutoConfirmation(this.orderData.id);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getOrderData();
        }
    }

    public onAddressChange(): void {
        this.getOrderData();
        if (this.orderData && (!this.orderData.confirmed || this.canEditServiceLevelOnConfirmed)) {
            this.getCourierServices();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "orders",
            actions: {
                "view": ["read_orders"]
            }
        };
    }

    public openExternalLink(link: string): void {
        window.open(link, "_blank");
    }
}
