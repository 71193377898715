import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
import {BoxSticker2Component, BoxStickerComponent} from "..";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {FormControl} from "@angular/forms";
import {takeUntil, distinctUntilChanged, debounceTime} from "rxjs";
import {Warehouse} from "src/modules/common/interfaces/warehouse.interface";
import {HelpersService} from "src/modules/common/services/helpers.service";

@Component({
    selector: "section-warehouse-boxes-list",
    template: `

        <h1 class="container-heading">Boxes</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1 class="flex row align-center">
                        <common-form-select *ngIf="hubList?.length"
                                            [options]="hubList"
                                            [multiple]="true"
                                            [selectAll]="true"
                                            [search]="true"
                                            (onClose)="hubSelect.setValue($event)"
                                            class="margin-right-10"
                                            style="width: 250px"
                                            label="Hubs"></common-form-select>

                        <common-autocomplete *ngIf="countries?.length"
                                             [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                             class="margin-right-10" style="width: 250px"
                                             (optionSelected)="countrySelect.setValue($event.value)"
                                             [errorText]="'Select country'" [required]="false"
                                             [image]="{path:'assets/images/flags',ext:'svg'}"
                                             [value]="countrySelect.value"></common-autocomplete>
                    </div>
                    <div data-actions>
                        <a mat-raised-button color="accent" (click)="upload()">
                            Link boxes to pallets
                        </a>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseBoxesListComponent implements Base.IComponent, OnInit, OnDestroy {

    public readonly state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public hubList: { name: string, value: any, name2: string }[];

    public hubSelect: FormControl = new FormControl([]);

    public countries: any[] = [];

    public countrySelect: FormControl = new FormControl(null);

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private helpers: HelpersService,
    ) {
    }

    private get url(): Table.ITableApi {
        return {
            url: [this.state.section, "boxes"],
            query: {
                hubs: this.hubSelect.value,
                country: this.countrySelect.value,
                relations: [
                    "WarehouseLocation:id,location",
                    "WarehousePackage:id,name",
                    "Inventory:id,box_id,inventory_conversion_id",
                    "Inventory.InventoryConversion:id,customers_sub_inventory,customers_inventory_name",
                    "Pallet:id,ref"
                ],
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "list",
                    title: "Items",
                    click: (row: any): void => {
                        this.modalService.open(BoxItemsListComponent, {
                            box: row,
                            modalWidth: 1000,
                            itemsType: "warehouse-transactions"
                        });
                    }
                },
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any): void => {
                        this.modalService.open(BoxStickerComponent, {
                            box: row
                        });
                    }
                },
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any): void => {
                        this.modalService.open(BoxSticker2Component, {
                            box: row,
                            warehouseName: row.warehouse_location.warehouse.name,
                            locationName: row.warehouse_location.location
                        });
                    }
                }
            ],
            api: this.url,
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "inventory.inventory_conversion.customers_sub_inventory",
                    name: "Inventory.InventoryConversion.customers_sub_inventory",
                    title: "Hub (from Inventory)",
                    render: (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    },
                    sortable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "warehouse_package.name",
                    name: "WarehousePackage.name",
                    title: "Package"
                },
                {
                    data: "inventories_count",
                    title: "Items",
                    searchable: false
                },
                {
                    data: "full",
                    title: "Full",
                    searchable: false,
                    render: (row: any): string => {
                        return row?.full ? "Yes" : "No";
                    }
                },
                {
                    data: "pallet.ref",
                    name: "Pallet.ref",
                    title: "Pallet",
                    sortable: false
                },
            ],
            infinity_scroll: true
        };

        this.changeDetectorRef.markForCheck();
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    name2: `<b>${hub.customers_inventory_name}</b>/${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "LinkBoxesToPallets",
            "back_to",
            btoa(this.router.url)
        ]);
    }


    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.getHubList();
        this.prepareCountriesList();

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                    this.tableRef.reload(this.url);
                }
            );

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                    if (this.countries.findIndex((country: any) => {
                        return country === value;
                    })) {
                        await this.getHubList(value);
                        this.tableRef.reload(this.url);
                    }
                }
            );

        this.spinnerService.hide();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-boxes",
            actions: {
                "browse": ["browse_boxes"]
            }
        };
    }
}
