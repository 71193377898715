import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Table2Component} from "src/modules/common/components/table2";
import {Base} from "src/modules/common/interfaces/base.interfaces";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Api, ApiService} from "src/modules/common/services/api.service";
import {Api3Service} from "src/modules/common/services/api3.service";
import {PartnerService} from "src/modules/common/services/partner.service";
import {Modal, ModalService} from "src/modules/section/services/modal.service";
import {PartnerBoxItemsListModalComponent} from "../items-list-modal/items-list-modal.component";
import {PartnerBoxWarehouseTransactionsModalComponent}
    from "../warehouse-transactions-modal/warehouse-transactions-modal.component";
import {StorageService} from "src/modules/common/services/storage.service";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "src/modules/common/services/spinner.service";
import {Warehouse} from "src/modules/common/interfaces/warehouse.interface";
import {HelpersService} from "src/modules/common/services/helpers.service";

@Component({
    selector: "partner-packages-boxes-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Boxes List</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1 class="flex row align-center">
                        <common-form-select *ngIf="hubList?.length"
                                            [options]="hubList"
                                            [multiple]="true"
                                            [selectAll]="true"
                                            [search]="true"
                                            (onClose)="hubSelect.setValue($event)"
                                            class="margin-right-10"
                                            style="width: 250px"
                                            label="Hubs"></common-form-select>

                        <common-autocomplete *ngIf="countries?.length"
                                             [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                             class="margin-right-10" style="width: 250px"
                                             (optionSelected)="countrySelect.setValue($event.value)"
                                             [errorText]="'Select country'" [required]="false"
                                             [image]="{path:'assets/images/flags',ext:'svg'}"
                                             [value]="countrySelect.value"></common-autocomplete>
                    </div>
                    <div data-actions>
                        <a mat-raised-button color="primary" (click)="addBox()">
                            Add New
                        </a>
                        <a mat-raised-button color="accent" (click)="upload()">
                            Upload CSV
                        </a>
                        <span style="margin-right: 10px">
                            <mat-checkbox [formControl]="onlyInventory">Show only inventory</mat-checkbox>
                        </span>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesBoxesListComponent implements OnInit, OnDestroy, Base.IComponent {
    public state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public onlyInventory: FormControl =
        new FormControl(this.storageService.get("only_inventory", true));

    public hubList: { name: string, value: any, name2: string }[];

    public hubSelect: FormControl = new FormControl([]);

    public countries: any[] = [];

    public countrySelect: FormControl = new FormControl(null);

    public constructor(
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiV3Service: Api3Service,
        private storageService: StorageService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private apiService: ApiService,
        private helpers: HelpersService,
    ) {

    }

    private get url(): Table.ITableApi {
        return {
            url: ["partner", PartnerService.partner.slug, "boxes"],
            query: {
                hubs: this.hubSelect.value,
                country: this.countrySelect.value,
                relations: [
                    "Pallet:id,ref",
                    "WarehouseLocation:id,location",
                    "WarehousePackage:id,name",
                    "Inventory.InventoryConversion"
                ],
                counts: [
                    "WarehouseTransactions"
                ],
                only_inventory: this.onlyInventory.value
            },
            version: 3,
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "partner_packages_boxes_list",
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    icon: "edit",
                    click: (row: any) => {
                        this.editBox(row);
                    },
                    disabledFn: (row: any) => {
                        return row.warehouse_transactions_count;
                    },
                },
                {
                    name: "delete",
                    title: "Delete",
                    icon: "delete",
                    click: (row: any) => {
                        this.delete(row.id);
                    },
                    disabledFn: (row: any) => {
                        return row.warehouse_transactions_count;
                    },
                },
                {
                    name: "history",
                    title: "Transactions history",
                    icon: "history",
                    click: (row: any) => {
                        this.showTransactions(row.id);
                    },
                    disabledFn: (row: any) => {
                        return !row.warehouse_transactions_count;
                    },
                },

                {
                    name: "recalculate",
                    title: "Recalculate quantity",
                    icon: "sync",
                    click: (row: any) => {
                        this.apiV3Service.post(`${this.state.section}/boxes/${row.id}/recalculate-inventory`)
                            .then(({code, message}: Api.IResponse): void => {
                                if (code === 200) {
                                    this.toastService.show(message, "success");
                                }
                            });
                    },
                }
            ],
            columns: [
                {
                    "data": "ref",
                    "title": "Ref",
                },
                {
                    data: "pallet.ref",
                    name: "Pallet.ref",
                    title: "Pallet",
                    sortable: false
                },
                {
                    "data": "inventory.inventory_conversion.customers_inventory_name",
                    "name": "Inventory.InventoryConversion.customers_inventory_name",
                    "title": "Hub (from Inventory)",
                    "render": (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    }
                },
                {
                    "data": "warehouse_location.location",
                    "name": "WarehouseLocation.location",
                    "title": "Warehouse Location"
                },
                {
                    "data": "warehouse_package.name",
                    "name": "WarehousePackage.name",
                    "title": "Warehouse Package"
                },
                {
                    "data": "inventories_count",
                    "title": "Items List",
                    render(row): string {
                        if (row.inventories_count === 0) {
                            return "";
                        }

                        return `
                            <button class='mat-mdc-raised-button mdc-button button-200' type='button'>
                                Items list
                            </button>`;
                    },
                    click: (row) => {
                        if (row.inventories_count) {
                            this.showItemsList(row.ref);
                        }
                    },
                    sortable: false,
                    searchable: false
                },
                {
                    "data": "inventories_count",
                    "title": "Total QTY",
                    searchable: false
                },
                {
                    "data": "inventories_free_count",
                    "title": "Available QTY",
                    searchable: false
                },
                {
                    data: "full",
                    title: "Full",
                    searchable: false,
                    render: (row: any): string => {
                        return row?.full ? "Yes" : "No";
                    }
                },
            ],
            api: this.url,
            export: {
                file_name: "Partner-Packages-Boxes-List",
            },
            showTotal: true
        };

        this.changeDetectorRef.markForCheck();
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    name2: `<b>${hub.customers_inventory_name}</b>/${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public async addBox(box: any): Promise<void> {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "add"
        ]);
    }

    public async editBox(box: any): Promise<void> {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "edit",
            "id",
            box.id
        ]);
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "box",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public async showItemsList(ref: string): Promise<void> {
        const response: Modal.IResponse = await this.modalService.open(PartnerBoxItemsListModalComponent, {
            partner: this.state.section_slug,
            box_ref: ref
        });
    }

    public async delete(id: number): Promise<void> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Box?")) {
            return;
        }
        const response = await this.apiV3Service.request(Api.EMethod.Delete,
            `partner/${PartnerService.partner.slug}/boxes/${id}`
        );
        if (response) {
            this.tableRef.reload();
        }
    }

    public async showTransactions(id: number): Promise<void> {
        const response: Modal.IResponse = await this.modalService.open(PartnerBoxWarehouseTransactionsModalComponent, {
            partner: this.state.section_slug,
            box_id: id
        });
    }

    ngOnInit(): void {
        this.prepareList();

        this.getHubList();
        this.prepareCountriesList();

        this.onlyInventory.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("only_inventory", value);
            this.tableRef.reload(this.url);
        });

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                    this.tableRef.reload(this.url);
                }
            );

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                    if (this.countries.findIndex((country: any) => {
                        return country === value;
                    })) {
                        await this.getHubList(value);
                        this.tableRef.reload(this.url);
                    }
                }
            );
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "packages-boxes",
            actions: {
                "browse": ["browse_part_masters"]
            }
        };
    }
}