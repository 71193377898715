<common-confirm></common-confirm>
<h1 class="container-heading">Order</h1>

<div *ngIf="orderData" class="references">
    <ng-template [ngIf]="!orderRefEditState">
        <span class="value ref" *ngIf="orderData.ref">{{ orderData.ref }}</span>
        <mat-icon *ngIf="!orderData.confirmed && !orderData.ref" (click)="orderRefEditState = true"
                  class="pointer icon-inline"
                  style="vertical-align: middle; height: 28px">
            add
        </mat-icon>
        <mat-icon *ngIf="!orderData.confirmed && orderData.ref" (click)="orderRefEditState = true"
                  class="pointer icon-inline"
                  style="vertical-align: middle; height: 28px">
            edit
        </mat-icon>
    </ng-template>
    <mat-form-field *ngIf="orderRefEditState" class="edit-field">
        <input class="edit-ref" matInput [formControl]="orderRefEditFormControl">
        <span matSuffix>
            <mat-icon class="text-success pointer"
                      (click)="orderRefEditState = false; updateOrder({ref: orderRefEditFormControl.value})">
                check
            </mat-icon>
            <mat-icon class="text-error pointer"
                      (click)="orderRefEditState = false; orderRefEditFormControl.setValue(orderData.ref)">
                close
            </mat-icon>
            </span>
    </mat-form-field>
    <input type="text" [value]="orderData.ref" #orderRef style="position: absolute; left: -9999px;">
    <mat-icon class="copy" (click)="copyRef()">content_copy</mat-icon>

    <div class="ref2">
        Ref 2:
        <ng-template [ngIf]="!orderRef2EditState">
            <span class="value" *ngIf="orderData.ref2">{{ orderData.ref2 }}</span>
            <mat-icon *ngIf="!orderData.confirmed && !orderData.ref2" (click)="orderRef2EditState = true"
                      class="pointer icon-inline"
                      style="vertical-align: middle; height: 32px">
                add
            </mat-icon>
            <mat-icon *ngIf="!orderData.confirmed && orderData.ref2" (click)="orderRef2EditState = true"
                      class="pointer icon-inline"
                      style="vertical-align: middle; height: 32px">
                edit
            </mat-icon>
            <div *ngIf="orderData.confirmed"
                 style="vertical-align: middle; height: 32px; width: 24px; display: inline-block;"></div>
        </ng-template>
        <mat-form-field *ngIf="orderRef2EditState" class="edit-field">
            <input matInput [formControl]="orderRef2EditFormControl">
            <span matSuffix>
            <mat-icon class="text-success pointer"
                      (click)="orderRef2EditState = false; updateOrder({ref2: orderRef2EditFormControl.value})">
                check
            </mat-icon>
            <mat-icon class="text-error pointer"
                      (click)="orderRef2EditState = false; orderRef2EditFormControl.setValue(orderData.ref2)">
                close
            </mat-icon>
            </span>
        </mat-form-field>
    </div>

    <mat-icon class="pointer priority priority-color-{{orderData.priority}}"
              (click)="updatePriority()"
              matTooltip="Set priority">
        flag
    </mat-icon>

</div>

<div class="order-actions" *ngIf="orderData">
    <button *ngIf="orderData.parent_order_id" mat-raised-button type="button"
            (click)="goToParent()" matBadgeColor="accent">
        Original order
    </button>
    <button mat-raised-button type="button"
            (click)="showAttachments()" matBadgeColor="accent" [matBadge]="attachmentsCount">
        Attachments
    </button>
    <button mat-raised-button type="button"
            (click)="showSidebar(true)" matBadgeColor="accent" [matBadge]="remarksCount.toString()">
        Remarks
    </button>

    <ng-container
            *ngIf="orderData && orderData.order_items_count &&  orderActions.needConfirm  && canConfirm && userService.validatePermissions(['confirm_order'])">
        <div class="button-group">
            <button type="button" mat-raised-button color="accent" (click)="confirmOrder()">
                <mat-icon class="vertical-middle">done</mat-icon>
                Confirm order
            </button>
            <button type="button" mat-raised-button color="accent" [matMenuTriggerFor]="confirmMenu" class="icon">
                <mat-icon class="vertical-middle">keyboard_arrow_down</mat-icon>
            </button>
        </div>
        <mat-menu #confirmMenu="matMenu" xPosition="before">
            <a mat-menu-item (click)="futureConfirm()">
                Future confirmation
            </a>
            <a mat-menu-item (click)="silentConfirm()" *userAllowed="'silent_confirm_orders'">
                Silent confirmation
            </a>
            <a mat-menu-item *ngIf="orderData.unreleased_outbound_order_items_count > 0"
               (click)="toggleAutoConfirmation()">
                <mat-icon color="accent">
                    {{ orderData.properties?.auto_confirmation === false ? "check_box_outline_blank" : "check_box" }}
                </mat-icon>
                Auto confirmation
            </a>
        </mat-menu>
    </ng-container>


    <ng-container *ngIf="showActionsMenu()">
        <mat-menu #actionsMenu="matMenu">
            <a *ngIf="orderActions.cnahgeStatus" mat-menu-item (click)="changeStatus()">Change status</a>
            <a *ngIf="userService.validatePermissions(['release_confirmation']) && orderData.confirmed"
               mat-menu-item (click)="releaseConfirmation()">
                Release from confirmation
            </a>
            <a mat-menu-item (click)="rebaseStatus()">
                Update statuses
            </a>
            <a mat-menu-item *ngIf="userService.validatePermissions(['delete_order']) &&
            orderData && !orderData.confirmed" (click)="cancelOrder()">
                Delete order
            </a>
            <a mat-menu-item *ngIf="userService.validatePermissions(['soft_delete_order']) &&
            orderData && !orderData.confirmed" (click)="cancelOrder(true)">
                Cancel order
            </a>
            <a mat-menu-item *ngIf="orderData && orderData.confirmed" (click)="releaseDocuments()">
                Release documents
            </a>
            <a mat-menu-item
               *ngIf="orderData && orderData.service_level.order_type.slug === 'regular_rma' && !orderData.outbound_releases_count"
               (click)="releaseOutbound()">
                Release without inbound
            </a>
            <a mat-menu-item *ngIf="orderData && orderData.service_level.order_type.slug !== 'transfer'"
               (click)="cloneOrder()">
                Clone order
            </a>
        </mat-menu>
        <button mat-raised-button [matMenuTriggerFor]="actionsMenu">
            Actions
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </ng-container>
</div>

<div class="order-confirm-on" *ngIf="orderData && !orderData.confirmed && orderData.confirm_on">
    Auto confirm in:
    <common-counter [endDate]="orderData.confirm_on" (onExpired)="onCounterExpired()"></common-counter>
</div>

<div class="container-columns">

    <div class="left">

        <mat-card *ngIf="isNoData" class="empty">
            <p>No data</p>
        </mat-card>

        <mat-card *ngIf="orderData">
            <mat-card class="info-card">
                <div class="item flex col" [class.rejected]="orderData.status.name === 'Order rejected'">
                    Status:
                    <span class="value">{{ orderData.status.name }}</span>
                </div>

                <div class="item flex col" *ngIf="orderData.created_by">
                    Created by:
                    <span class="value">{{ orderData.created_by.name }}</span>
                </div>

                <div class="item flex col" *ngIf="orderData.confirmed && orderData.confirmed_by">
                    Confirmed by:
                    <span class="value">{{ orderData.confirmed_by.name }}</span>
                </div>

                <div class="item flex col">
                    Created at:
                    <span class="value">{{ orderData.created_at | date: "MMM d, y h:mm a" }}</span>
                </div>
            </mat-card>
        </mat-card>
        <div class="incoming-terms margin-bottom-20 label" *ngIf="orderData && !orderInIncotermsEditFormControl">
            INCOMING TERMS
            <ng-template [ngIf]="!orderIncotermsEditState">
                <span class="value" *ngIf="orderData.incoterms">{{ orderData.incoterms }}</span>
                <mat-icon *ngIf="!orderData.confirmed && !orderData.incoterms"
                          (click)="orderIncotermsEditState = true"
                          class="pointer icon-inline"
                          style="vertical-align: middle; height: 32px">
                    add
                </mat-icon>
                <mat-icon *ngIf="!orderData.confirmed && orderData.incoterms"
                          (click)="orderIncotermsEditState = true"
                          class="pointer icon-inline"
                          style="vertical-align: middle; height: 32px">
                    edit
                </mat-icon>
            </ng-template>
            <mat-form-field *ngIf="orderIncotermsEditState" class="edit-field">
                <mat-label>Incoterms</mat-label>
                <mat-select [formControl]="orderIncotermsEditFormControl"
                            [value]="orderData.incoterms || 'EXW'">
                    <mat-option
                            *ngFor="let option of ['EXW', 'FCA', 'FAS', 'FOB', 'CPT', 'CFR', 'CIF', 'CIP', 'DPU', 'DAP', 'DDP']"
                            [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <span matSuffix>
                    <mat-icon class="text-success pointer"
                              (click)="orderIncotermsEditState = false; updateOrder({incoterms: orderIncotermsEditFormControl.value})">
                        check
                    </mat-icon>
                    <mat-icon class="text-error pointer"
                              (click)="orderIncotermsEditState = false; orderIncotermsEditFormControl.setValue(orderData.incoterms)">
                        close
                    </mat-icon>
                </span>
            </mat-form-field>
        </div>

        <mat-card *ngIf="orderData">
            <mat-card-content>
                <section-order-address [orderData]="orderData"
                                       (onUpdate)="onAddressChange()"></section-order-address>
            </mat-card-content>
        </mat-card>

        <mat-divider></mat-divider>

        <table class="table" *ngIf="orderData">
            <tr>
                <td class="vertical-middle">
                    <h2 class="mat-h2 margin-bottom-0" style="border-bottom:none !important;">
                        Service level
                        <span *ngIf="orderData?.service_level?.name
                        && (!canEditServiceLevel || orderData.confirmed)
                        && !canEditServiceLevelOnConfirmed" class="sub">
                            {{ orderData.service_level.name }}
                        </span>

                        <span *ngIf="orderData?.service_level?.name
                            && ((canEditServiceLevel && !orderData.confirmed) || canEditServiceLevelOnConfirmed)"
                              class="sub">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-select formControlName="service_level">
                                    <mat-option *ngFor="let level of orderServiceLevels"
                                                [value]="level.id">
                                        {{ level.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                    </h2>
                </td>
                <td class="vertical-middle">
                    <ng-container *ngIf="orderData?.confirmed && orderData?.deliver_on">
                        Requested delivery date:
                        <br>
                        <b class="value">
                            {{ orderData.deliver_on }}
                        </b>
                    </ng-container>

                    <div *ngIf="orderData && !orderData.confirmed" class="flex row"
                         style="align-items: center">
                        <common-form-date
                                label="Requested delivery date"
                                [value]="orderDeliverOnEditFormControl.value"
                                (valueChange)="orderDeliverOnEditFormControl.setValue($event)"
                        ></common-form-date>
                        <mat-icon class="text-success pointer"
                                  (click)="orderDeliverOnEditState = false;
                                           updateOrder({deliver_on: orderDeliverOnEditFormControl.value})">
                            check
                        </mat-icon>
                        <mat-icon class="text-error pointer"
                                  (click)="orderDeliverOnEditFormControl.reset(orderData.deliver_on)">
                            close
                        </mat-icon>
                    </div>
                </td>
            </tr>
            <tr *ngIf="orderData && !orderData.confirmed && warehouseLocalTime">
                <td colspan="2" class="text-right">
                    Warehouse local time:
                    <b>{{ warehouseLocalTime | async }}</b>
                </td>
            </tr>
        </table>

        <div *ngIf="orderData && orderData.confirmed && canEditServiceLevelOnConfirmed" class="pull-right">
            <button type="button" color="primary" mat-raised-button (click)="editSL = !editSL">
                Edit courier service
            </button>
        </div>

        <div class="servicelevel-description" *ngIf="orderData?.service_level">
            {{ orderData.service_level.description }}
        </div>

        <div class="servicelevel-description"
             *ngIf="orderData && orderData.service_level.order_type.slug === 'transfer'">

            <ng-container *ngIf="canFindBySlug && transferDir">
                <ng-container *ngIf="transferDir.outbound">
                    <mat-icon class="vertical-middle"
                              *ngIf="transferDir.outbound.type === 'warehouse'">
                        home
                    </mat-icon>
                    <i *ngIf="transferDir.outbound.type === 'site'"
                       class="fa fa-map-marker"></i>
                    <i *ngIf="transferDir.outbound.type === 'oem'" class="fa fa-microchip"></i>
                    {{ transferDir.outbound.name }}
                </ng-container>
                <mat-icon class="vertical-middle">arrow_forward</mat-icon>
                <ng-container *ngIf="transferDir.inbound">
                    <mat-icon class="vertical-middle"
                              *ngIf="transferDir.inbound.type === 'warehouse'">
                        home
                    </mat-icon>
                    <i *ngIf="transferDir.inbound.type === 'site'" class="fa fa-map-marker"></i>
                    <i *ngIf="transferDir.inbound.type === 'oem'" class="fa fa-microchip"></i>
                    {{ transferDir.inbound.name }}
                </ng-container>
            </ng-container>

            <common-simple-spinner
                    *ngIf="canFindBySlug && !transferDir"></common-simple-spinner>

            <ng-container *ngIf="!canFindBySlug">
                {{ orderData.ref2 }}
            </ng-container>
        </div>

        <div *ngIf="orderData && (!orderData.confirmed || (canEditServiceLevelOnConfirmed && editSL))
                        && courierServices" class="courier-services">
            <mat-radio-group [formControl]="courier_service">
                <div class="courier-service mat-elevation-z2" [class.disabled]="courierDisabled(courier)"
                     *ngFor="let courier of courierServices">
                    <div>
                        <mat-radio-button [value]="courier.id"></mat-radio-button>
                    </div>
                    <div *ngIf="courier.courier && courier.courier.icon_path">
                        <img [src]="courier.courier.icon_path"
                             alt=""
                             width="50">
                    </div>
                    <div style="width:80%">
                        <h3>
                            {{ courier.service_name }}
                            <ng-template [ngIf]="courier.price && !orderData.partner.properties.hide_courier_price">
                                -
                                <span class="text-success">
                                    {{ courier.price }} {{ courier.currency }}
                                </span>
                            </ng-template>
                            <ng-template [ngIf]="courier.delay_reasons && courier.delay_reasons.length">
                                <mat-icon class="mat-icon-alert" [matTooltip]="courier.delay_reasons.join(' and ')">
                                    report
                                </mat-icon>
                            </ng-template>
                        </h3>

                        <b>Description:</b> {{ courier.description ? courier.description + " | " : "" }}
                        <span *ngIf="courier.delivery_type ==='int'" class="text-error">International only</span>
                        <span *ngIf="courier.delivery_type ==='dom'" class="text-error">Domestic only</span>

                        <br/>

                        <b>Service
                            SLA:</b> {{
                            (courier.days) ? 'delivery within ' + courier.days + ' business days' :
                                    (courier.hours_to_sla) ? 'delivery within ' + courier.hours_to_sla + ' hours'
                                            : 'missing sla'
                        }}<br/>

                        <ng-template [ngIf]="courier.distribution_list">
                            <b>In price list</b><br/>
                        </ng-template>
                        <ng-template
                                [ngIf]="!(['RUSH', 'SCHD', 'SCHLD', 'Rush', 'NFO'].includes(courier.service_name)) && courier.eta">
                            <b>Estimated delivery:</b>
                            {{ dateToHumanTime(courier.eta) }}
                            ({{ courier.eta_timezone }})
                            <br>
                        </ng-template>
                        <ng-template
                                [ngIf]="!courier.eta && courier.estimation_human">
                            <b>Estimated delivery:</b>
                            {{ courier.estimation_human }}
                            <br>
                        </ng-template>
                        <ng-template [ngIf]="courier.after_hours">
                            <b>Cutoff: ({{ courier.cutt_off }})</b>
                            <span [class]="isAfter(courier.after_hours)
                                ? 'text-warn' : 'text-success'">
                                {{ courier.after_hours }}
                            </span>
                        </ng-template>
                    </div>
                    <div style="width:20%">
                        <ng-template [ngIf]="courier.distance_text">
                            <b>Distance:</b> {{ courier.distance_text }}
                        </ng-template>
                        <ng-template [ngIf]="!courier.distance_text">
                            <b>Distance:</b>
                            <small class="text-error">
                                Can't calculate distance
                            </small>
                        </ng-template>
                        <br>
                        <b>Total weight:</b>
                        <ng-template [ngIf]="courier.total_weight">
                            {{ courier.total_weight }}
                        </ng-template>
                        <ng-template [ngIf]="!courier.total_weight">
                            <small class="text-error">
                                Missing weight in part master
                            </small>
                        </ng-template>
                    </div>
                </div>
            </mat-radio-group>
        </div>
        <ng-template
                [ngIf]="courierService && (courierService.service_name !== null || courierService.description !== null)">
            <mat-divider></mat-divider>
            <h3>Courier Service</h3>
        </ng-template>
        <ng-template [ngIf]="courierService && courierService.service_name !== null">
            <b>Name: </b>{{ courierService.service_name }}<br/>
        </ng-template>
        <ng-template [ngIf]="courierService && courierService.description !== null">
            <b>Description: </b>{{ courierService.description }}<br/>
        </ng-template>
        <br/>
        <ng-template [ngIf]="courierService">
            <mat-divider></mat-divider>
        </ng-template>

        <mat-card *ngIf="orderData">
            <mat-card *ngIf="orderData">
                <div class="flex row align-center margin-bottom-20">
                    <div class="item half flex row align-center ">
                        <b class="margin-right-10">Out incoterms: </b>
                        <ng-template [ngIf]="!orderOutIncotermsEditState">
                            <div class="value" *ngIf="orderData.out_incoterms">{{ orderData.out_incoterms }}</div>
                            <mat-icon *ngIf="!orderData.confirmed && !orderData.out_incoterms"
                                      (click)="orderOutIncotermsEditState = true"
                                      class="pointer">
                                add
                            </mat-icon>
                            <mat-icon *ngIf="!orderData.confirmed && orderData.out_incoterms"
                                      (click)="orderOutIncotermsEditState = true"
                                      class="pointer"
                            >
                                edit
                            </mat-icon>
                        </ng-template>
                        <mat-form-field *ngIf="orderOutIncotermsEditState" class="edit-field">
                            <mat-label>Incoterms</mat-label>
                            <mat-select [formControl]="orderOutIncotermsEditFormControl"

                                        [value]="orderData.out_incoterms || 'EXW'">
                                <mat-option
                                        *ngFor="let option of ['none', 'EXW', 'FCA', 'FAS', 'FOB', 'CPT', 'CFR', 'CIF', 'CIP', 'DPU', 'DAP', 'DDP']"
                                        [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                            <span matSuffix>
                                <mat-icon class="text-success pointer"
                                          (click)="orderOutIncotermsEditState = false; updateOrder({out_incoterms: orderOutIncotermsEditFormControl.value})">
                                    check
                                </mat-icon>
                                <mat-icon class="text-error pointer"
                                          (click)="orderOutIncotermsEditState = false; orderOutIncotermsEditFormControl.setValue(orderData.out_incoterms)">
                                    close
                                </mat-icon>
                                </span>
                        </mat-form-field>
                    </div>
                    <div class="item half flex row align-center ">
                        <b class="margin-right-10">In incoterms: </b>
                        <ng-template [ngIf]="!orderInIncotermsEditState">
                                <span class="value"
                                      *ngIf="orderData.in_incoterms">{{ orderData.in_incoterms }}</span>
                            <mat-icon *ngIf="!orderData.confirmed && !orderData.in_incoterms"
                                      (click)="orderInIncotermsEditState = true"
                                      class="pointer">
                                add
                            </mat-icon>
                            <mat-icon *ngIf="!orderData.confirmed && orderData.in_incoterms"
                                      (click)="orderInIncotermsEditState = true"
                                      class="pointer">
                                edit
                            </mat-icon>
                        </ng-template>
                        <mat-form-field *ngIf="orderInIncotermsEditState" class="edit-field">
                            <mat-label>Incoterms</mat-label>
                            <mat-select [formControl]="orderInIncotermsEditFormControl"

                                        [value]="orderData.in_incoterms || 'EXW'">
                                <mat-option
                                        *ngFor="let option of ['none', 'EXW', 'FCA', 'FAS', 'FOB', 'CPT', 'CFR', 'CIF', 'CIP', 'DPU', 'DAP', 'DDP']"
                                        [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                            <span matSuffix>
                        <mat-icon class="text-success pointer"
                                  (click)="orderInIncotermsEditState = false; updateOrder({in_incoterms: orderInIncotermsEditFormControl.value})">
                            check
                        </mat-icon>
                        <mat-icon class="text-error pointer"
                                  (click)="orderInIncotermsEditState = false; orderInIncotermsEditFormControl.setValue(orderData.in_incoterms)">
                            close
                        </mat-icon>
                    </span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="flex row align-center space-between">
                    <div *ngIf="orderData.service_level.coverage">
                        <span class="label">Partner service level: </span>
                        <span>{{ orderData.service_level.coverage.display_name }}</span>
                    </div>
                    <div *ngIf="orderData.service_level.order_type">
                        <span class="label">Order type: </span>
                        <span>{{ orderData.service_level.order_type.display_name }}</span>
                    </div>
                    <div *ngIf="orderData.service_level.shipping_mode">
                        <span class="label">Shipping mode: </span>
                        <span>{{ orderData.service_level.shipping_mode.display_name }}</span>
                    </div>
                    <div *ngIf="orderData.service_level.courier_service">
                        <span class="label">Courier service: </span>
                        <span>
                    <ng-template
                            [ngIf]="orderData.courier_service && orderData.courier_service.service_name">
                        <span style="color: #4caf50">
                            {{ orderData.courier_service.description || orderData.courier_service.service_name }}
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="(!orderData.courier_service || !orderData.courier_service.service_name)
                                && orderData.service_level && orderData.service_level.courier_service">
                        <span>
                            {{ orderData.service_level.courier_service.service_name }}
                            - {{ orderData.service_level.courier_service.description }}
                        </span>
                    </ng-template>
                </span>
                    </div>
                </div>
            </mat-card>

            <mat-card class="activities" *userAllowed="['browse_activities']">
                <section-order-activities #activitiesComponent [orderData]="orderData" *ngIf="orderData"
                                          [listType]="'paginated'" [perPage]="3"></section-order-activities>
            </mat-card>
        </mat-card>
    </div>

    <div class="right" style="min-width: 680px" *ngIf="orderData">
        <mat-card>
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>description</mat-icon>
                            Order details
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="content">
                                <h2 class="mat-h2 heading">
                                    Service request

                                    <mat-icon
                                            (click)="orderSrEditState = true" class="pointer icon-inline"
                                            style="vertical-align: middle; height: 28px">
                                        edit
                                    </mat-icon>
                                </h2>
                                <div *ngIf="!orderSrEditState && orderData.service_request">
                                    <p>{{ orderData.service_request.ref }}</p>
                                </div>
                                <div *ngIf="orderSrEditState">
                                    <mat-form-field>
                                        <input matInput [formControl]="orderSrEditFormControl">
                                    </mat-form-field>
                                    <div>
                                        <button mat-raised-button type="button" color="accent"
                                                (click)="orderSrEditState = false;
                                                      updateOrder({sr: orderSrEditFormControl.value})">
                                            Update
                                        </button>
                                        &nbsp;
                                        <button mat-raised-button type="button"
                                                (click)="orderSrEditState = false;
                                                      orderSrEditFormControl.setValue(orderData.service_request?.ref || null)">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="flex row space-between">
                                    <div class="half">
                                        <h2 class="mat-h2 heading">
                                            Remarks
                                            <mat-icon
                                                    (click)="orderRemarksEditState = true"
                                                    class="pointer icon-inline"
                                                    style="vertical-align: middle; height: 28px">
                                                edit
                                            </mat-icon>
                                        </h2>
                                        <div *ngIf="!orderRemarksEditState">
                                            <p [innerHTML]="orderData.remarks | linebreaks"></p>
                                        </div>
                                        <div *ngIf="orderRemarksEditState">
                                            <mat-form-field style="width: 100%">
                                                <textarea matInput
                                                          [formControl]="orderRemarksEditFormControl"></textarea>

                                            </mat-form-field>
                                            <div>
                                                <button mat-raised-button type="button" color="accent"
                                                        (click)="orderRemarksEditState = false;
                                                      updateOrder({remarks: orderRemarksEditFormControl.value})">
                                                    Update
                                                </button>
                                                &nbsp;
                                                <button mat-raised-button type="button"
                                                        (click)="orderRemarksEditState = false;
                                                      orderRemarksEditFormControl.setValue(orderData.remarks)">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="half">
                                        <h2 class="mat-h2 heading">
                                            RMA
                                            <mat-icon
                                                    (click)="orderRmaEditState = true"
                                                    class="pointer icon-inline"
                                                    style="vertical-align: middle; height: 28px">
                                                edit
                                            </mat-icon>
                                        </h2>
                                        <div *ngIf="!orderRmaEditState">
                                            <p [innerHTML]="orderData.rma | linebreaks"></p>
                                        </div>
                                        <div *ngIf="orderRmaEditState">
                                            <mat-form-field style="width: 100%">
                                                        <textarea matInput
                                                                  [formControl]="orderRmaEditFormControl"></textarea>
                                            </mat-form-field>
                                            <div class="actions">
                                                <button mat-raised-button color="accent" type="button"
                                                        (click)="orderRmaEditState = false;
                                                              updateOrder({rma: orderRmaEditFormControl.value})">
                                                    Update
                                                </button>
                                                &nbsp;
                                                <button mat-raised-button type="button"
                                                        (click)="orderRmaEditState = false;
                                                              orderRmaEditFormControl.setValue(orderData.remarks)">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="orderData.partner.slug === 'modix'">
                                    <h2 class="mat-h2 heading">
                                        Reseller
                                        <mat-icon
                                                (click)="editReseller = true" class="pointer icon-inline"
                                                style="vertical-align: middle; height: 28px">
                                            edit
                                        </mat-icon>
                                    </h2>

                                    <div *ngIf="editReseller">
                                        <div>
                                            <section-order-reseller-select
                                                    [value]="orderData.resellers.length ? orderData.resellers[0].name: null"
                                                    (valueChanges)="newReseller = $event"
                                            ></section-order-reseller-select>
                                        </div>
                                        <div class="actions">
                                            <button mat-raised-button color="accent"
                                                    (click)="editReseller = false;
                                                      updateOrder({modix_reseller: newReseller })">
                                                Update
                                            </button>
                                            &nbsp;
                                            <button mat-raised-button
                                                    (click)="editReseller = false;newReseller = null">
                                                Cancel
                                            </button>
                                        </div>
                                        <br>
                                    </div>

                                    <div *ngIf="!editReseller">
                                        <span *ngFor="let reseller of orderData.resellers">
                                            {{ reseller.name }}
                                        </span>
                                    </div>

                                </div>

                                <div class="margin-bottom-20">
                                    <h2 class="mat-h2 heading margin-bottom-0">Custom fields:</h2>
                                    <div class="custom-fields"
                                         *ngIf="orderData.custom_fields && orderData.custom_fields.length > 0">
                                        <div *ngFor="let field of (orderData.custom_fields || [])"
                                             class="custom-field">
                                            <div *ngIf="field.key === 'icon_link'; else notLink">
                                                <div class="item" *ngIf="field.value">
                                                    <a href="{{field.value.url}}" target="_blank">
                                                        <img height="70px" src="{{field.value.icon}}"
                                                             alt="{{field.value.url}}">
                                                    </a>
                                                </div>
                                            </div>
                                            <ng-template #notLink>
                                                <div class="item">
                                                    <span class="label">{{ customFieldKeyName(field.key) }}: </span>
                                                    <ng-container *ngIf="field.value">
                                                        {{ field.value | json }}
                                                    </ng-container>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div *ngIf="!orderData.confirmed">
                                        <button type="button" mat-raised-button color="accent"
                                                (click)="editCustomFields()">
                                            Edit
                                        </button>
                                    </div>
                                </div>

                                <div *ngIf="orderData.customer_account || orderData.customer_account_courier_company || orderData.customer_account_service_level">
                                    <h2 class="mat-h2 heading" style="margin-bottom: 0">Customer account</h2>
                                    <div class="custom-fields" style="padding-top: 0">
                                        <div class="custom-field" *ngIf="orderData.customer_account">
                                            <div class="item">
                                                <span class="label">Account number: </span>
                                                {{ orderData.customer_account.name }}
                                            </div>
                                        </div>
                                        <div class="custom-field"
                                             *ngIf="orderData.customer_account_courier_company">
                                            <div class="item">
                                                <span class="label">Courier company: </span>
                                                {{ orderData.customer_account_courier_company.name }}
                                            </div>
                                        </div>
                                        <div class="custom-field"
                                             *ngIf="orderData.customer_account_service_level">
                                            <div class="item">
                                                <span class="label">Service level: </span>
                                                {{ orderData.customer_account_service_level.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <section-order-view-shipments
                                        *userAllowed="'browse_shipments'"
                                        [orderData]="orderData"
                                        [transferDir]="transferDir"
                                        [state]="state">
                                </section-order-view-shipments>
                            </div>
                        </ng-template>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>assignment</mat-icon>
                            Order jobs
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="content">
                                <section-order-jobs [orderData]="orderData"></section-order-jobs>
                            </div>
                        </ng-template>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>assignment</mat-icon>
                            Order lifecycle
                        </ng-template>
                        <ng-template matTabContent>
                            <div class="content">
                                <section-order-lifecycle [orderData]="orderData"
                                                         [state]="state"></section-order-lifecycle>
                            </div>
                        </ng-template>
                    </mat-tab>

                    <ng-container *userAllowed="'browse_milestones'">
                        <mat-tab *ngIf="orderData">
                            <ng-template mat-tab-label>
                                <mat-icon>thumbs_up_down</mat-icon>
                                SLA fulfillment
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="content">
                                    <section-order-sla [orderData]="orderData"></section-order-sla>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<mat-card class="center" *ngIf="orderData && orderData.service_level.order_type.slug  !== 'informative'">
    <mat-card-content>
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>assignment</mat-icon>
                    Order items
                </ng-template>
                <div class="content">
                    <section-order-view-items [order]="orderData"
                                              [state]="state"
                                              [transferDir]="transferDir"
                                              (itemsUpdate)="onItemsUpdate()"
                                              (canFindBySlug)="onCanFindBySlug($event)">
                    </section-order-view-items>
                </div>
            </mat-tab>
            <mat-tab *ngIf="toNumber(orderData.warehouse_transactions_no_items) > 0">
                <ng-template mat-tab-label>
                    <span class="text-error" (click)="adjustTransactions()">
                        <mat-icon>assignment</mat-icon>
                        Transactions without items
                    </span>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<section-remarks-sidebar #sidebar></section-remarks-sidebar>

<ng-template [ngIf]="orderData">
    <button type="button" mat-mini-fab color="accent" class="minimize-followup-button"
            *ngIf="storageService.get('followUpFormMinimized')"
            matBadgeColor="accent"
            (click)="openFollowupForm()"
            matTooltip="View minimized followup">
        <mat-icon class="fa-rotate-90"> call_received</mat-icon>
    </button>

    <button type="button" mat-mini-fab color="accent" *userAllowed="'can_see_shift'"
            class="followup-button"
            [matMenuTriggerFor]="followUpMenu"
            [matBadgeHidden]="!(orderData.followups_count*1)"
            [matBadge]="orderData.followups_count"
            matTooltip="Followups">
        <mat-icon>update</mat-icon>
    </button>
    <mat-menu #followUpMenu="matMenu">
        <a mat-menu-item (click)="openFollowupForm()">New followup</a>
        <a mat-menu-item *ngIf="!!(orderData.followups_count*1)" (click)="goToFollowups()">
            View followups
        </a>
    </mat-menu>

</ng-template>

