<mat-card>

    <h1 class="color-accent">Select pallet to update</h1>

    <form [formGroup]="transferForm" (submit)="submit()">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
<mat-select formControlName="partner_id"  required>
                    <mat-option *ngFor="let partner of partners" [value]="partner.value">
                        {{partner.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="full">
                <common-form-select
                        label="Pallet"
                        [options]="palletsSelect"
                        [search]="true"
                        [required]="true"
                        [value]="transferForm.value.pallet_id"
                        (valueChange)="transferForm.get('pallet_id').setValue($event)">
                </common-form-select>
            </div>

            <div class="full" *ngIf="subs.length">
                <common-form-select
                        label="Sub warehouse"
                        [options]="subs"
                        [required]="true"
                        [value]="transferForm.value.sub_inventory_id"
                        (valueChange)="transferForm.get('sub_inventory_id').setValue($event)">
                </common-form-select>
            </div>

            <mat-form-field class="full" *ngIf="showLocations && transferForm.value.pallet_id">
                <mat-label>Warehouse location transfer to</mat-label>
                <input #trigger="matAutocompleteTrigger" type="text" matInput [formControl]="locationSearchInput"
                       [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                  (optionSelected)="onOptionSelected($event)">
                    <ng-container *ngFor="let option of locations_filtered | async">
                        <mat-option [value]="option"
                        [disabled]="selectedPalletData && option.inventory && selectedPalletData.inventory?.inventory_conversion_id !== option.inventory?.inventory_conversion_id">
                            {{option.location}}
                            <ng-container
                                    *ngIf="selectedPalletData && option.inventory && selectedPalletData.inventory?.inventory_conversion_id !== option.inventory?.inventory_conversion_id">
                                &nbsp;
                                <i>(belongs to another hub)</i>
                            </ng-container>
                        </mat-option>
                    </ng-container>
                    <mat-option [disabled]="true">
                        Try the search if the desired location is not listed or add a new one
                    </mat-option>
                    <mat-option (click)="addLocation(locationSearchInput.value)">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>


        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!transferForm.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
