import {ChangeDetectionStrategy, Component, EventEmitter, Input, ViewEncapsulation} from "@angular/core";

export interface IContextMenuOption {
    name: string;
    value: any;
    icon?: string;
}


@Component({
    selector: "common-context-menu",
    template: `
        <button mat-mini-fab class="transparent" [matMenuTriggerFor]="menu" *ngIf="options.length">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let option of options" (click)="optionSelected.emit(option.value)">
                <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
                <span>{{ option.name }}</span>
            </button>
        </mat-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./menu.component.scss"
    ]
})
export class CommonContextMenuComponent {

    @Input()
    public options: IContextMenuOption[] = [];

    public optionSelected: EventEmitter<any> = new EventEmitter();
}
