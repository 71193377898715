import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
//import {BoxSticker2Component, BoxStickerComponent} from "..";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table2Component} from "src/modules/common/components/table2";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Warehouse} from "src/modules/common/interfaces/warehouse.interface";
import {PalletStickerComponent} from "../sticker/sticker.component";
import {PalletSticker2Component} from "../sticker-2/sticker.component";
import {FormControl} from "@angular/forms";
import {takeUntil, distinctUntilChanged, debounceTime} from "rxjs";
import {HelpersService} from "src/modules/common/services/helpers.service";

@Component({
    selector: "section-warehouse-pallets-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Pallets List</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable" (onRowsSelected)="setSelectedRows($event)">
                <div row1 class="flex row align-center">
                    <common-form-select *ngIf="hubList?.length"
                        [options]="hubList"
                        [multiple]="true"
                        [selectAll]="true"
                        [search]="true"
                        (onClose)="hubSelect.setValue($event)"
                        class="margin-right-10"
                        style="width: 250px"
                        label="Hubs"></common-form-select>

                    <common-autocomplete *ngIf="countries?.length"
                        [options]="countries" placeholder="Country" [allowFreeInput]="false"
                        class="margin-right-10" style="width: 250px"
                        (optionSelected)="countrySelect.setValue($event.value)"
                        [errorText]="'Select country'" [required]="false"
                        [image]="{path:'assets/images/flags',ext:'svg'}"
                        [value]="countrySelect.value"></common-autocomplete>

                    <div *ngIf="selectedRows.length">
                        <button type="button" mat-raised-button color="accent"
                                (click)="printSelected()">
                            Print selected
                        </button>
                    </div>
                </div>
                <div data-actions>
                        <a mat-raised-button color="accent" (click)="upload()">
                            Link boxes to pallets
                        </a>
                </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehousePalletsListComponent implements Base.IComponent, OnInit, OnDestroy {

    public readonly state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public selectedRows: any[] = [];

    public hubList: { name: string, value: any, name2: string }[];

    public hubSelect: FormControl = new FormControl([]);

    public countries: any[] = [];

    public countrySelect: FormControl = new FormControl(null);

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private helpers: HelpersService,
    ) {
    }

    private get url(): Table.ITableApi {
        return {
            url: [this.state.section, "pallets"],
            query: {
                hubs: this.hubSelect.value,
                country: this.countrySelect.value,
                relations: ["WarehouseLocation.Warehouse", "Boxes", "Inventory.InventoryConversion", "Partner"],
                counts: [
                    "Boxes",
                ],
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any) => {
                        this.getStickers([row], true);
                    },
                },
                {
                    name: "print-alt",
                    title: "Print sticker",
                    icon: "print",
                    click: (row: any) => {
                        this.getStickers([row]);
                    },
                }
            ],
            api: this.url,
            columns: [
                {
                    "data": "ref",
                    "title": "Ref",
                },
                {
                    "data": "inventory.inventory_conversion.customers_inventory_full_name",
                    "name": "Inventory.InventoryConversion.customers_inventory_name",
                    "title": "Hub (from Inventory)",
                    "render": (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    }
                },
                {
                    "data": "warehouse_location.location",
                    "name": "WarehouseLocation.location",
                    "title": "Warehouse Location"
                },
                {
                    "data": "boxes_count",
                    "name": "boxes_count",
                    "title": "Boxes",
                    "searchable": false
                }
            ],
            export: {
                file_name: "Warehouse-Pallets-List",
            },
            multi_select: true,
            infinity_scroll: true
        };

        this.changeDetectorRef.markForCheck();
    }

    public setSelectedRows(rows: any): void {
        this.selectedRows = rows;
        this.changeDetectorRef.markForCheck();
    }

    public printSelected(): void {
        this.getStickers(this.selectedRows, true);
    }

    public async getStickers(pallets: Warehouse.IPallet[], withBoxes = false): Promise<void> {
        await this.modalService.open(withBoxes
                ? PalletStickerComponent
                : PalletSticker2Component, {
            pallet: pallets[0],
            pallets,
            state: this.state,
            warehouseName: pallets[0].warehouse_location.warehouse.name,
            locationName: pallets[0].warehouse_location.location
        });
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    name2: `<b>${hub.customers_inventory_name}</b>/${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "LinkBoxesToPallets",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.getHubList();
        this.prepareCountriesList();

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                    this.tableRef.reload(this.url);
            }
        );

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                if (this.countries.findIndex((country: any) => {
                    return country === value;
                })) {
                    await this.getHubList(value);
                    this.tableRef.reload(this.url);
                }
            }
        );

        this.spinnerService.hide();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-pallets",
            actions: {
                "browse": ["browse_boxes"]
            }
        };
    }
}
