<mat-card>

    <h1>Select item to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half" *ngIf="part_masters">
                <common-form-select [options]="part_masters"
                                    [search]="true"
                                    [required]="true"
                                    label="Select item"
                                    [value]="form.value.part_master_id"
                                    (valueChange)="onPMSelected($event)"
                ></common-form-select>
            </div>

            <div class="half" *ngIf="form.value.part_master_id">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="outbound"
                        [part_master_in_stock]="true"
                        [value]="form.value.inventory_conversion_id"
                        [part_master_id]="form.value.part_master_id"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <mat-form-field class="half" *ngIf="!form.value.part_master_id">
                <mat-label>Hub</mat-label>
                <input matInput disabled required>
            </mat-form-field>


            <div class="full padding-10" *ngIf="inventories && inventories.length"
                 style="max-height: 800px; overflow: auto">
                <h2>Inventory in stock:</h2>

                <mat-radio-group>
                    <mat-card *ngFor="let inventory of inventories"
                              class="tile mat-elevation-z5 margin-bottom-10"
                              (click)="selectInventory(inventory)">

                        <mat-card-header>
                            <b>{{ inventory.inventory.item }}</b>

                            <span *ngIf="inventory.inventory.configurations">
                                | Conf: {{ inventory.inventory.configurations }}
                            </span>
                        </mat-card-header>

                        <div class="flex row padding-top-10">
                            <div class="min">
                                <mat-radio-button [value]="inventory"></mat-radio-button>
                            </div>
                            <div class="max flex row">

                                <div class="third">
                                    Serial: {{ inventory.inventory.serial ? inventory.inventory.serial : "---" }}
                                </div>
                                <div class="third">
                                    Batch: {{ inventory.inventory.batch ? inventory.inventory.batch : "---" }}
                                </div>
                                <div class="third">
                                    Revision:
                                    {{ inventory.inventory.rev ? inventory.inventory.rev : "---" }}
                                </div>
                                <div class="third">
                                    Lot:
                                    {{ inventory.inventory.lot ? inventory.inventory.lot : "---" }}
                                </div>
                                <div class="full">
                                    Location:
                                    <span *ngIf="inventory.inventory.warehouse_location">
                                        {{ inventory.inventory.warehouse_location.location }}
                                    </span>
                                </div>
                                <div class="full">
                                    Qty: {{ inventory.sum }}
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </mat-radio-group>
            </div>

            <div class="full" *ngIf="inventories && !inventories.length">
                <h2>Inventory in stock:</h2>

                <mat-card class="tile mat-elevation-z5">
                    <p>No items to show</p>
                </mat-card>
            </div>

            <div class="full">
                <h2>Data to change:</h2>
            </div>

            <mat-form-field class="half">
                <mat-label>Quantity</mat-label>
                <input matInput required formControlName="quantity" min="1" [max]="selected_inventory_sum"
                       type="number">
            </mat-form-field>

            <div class="half">
                &nbsp;
            </div>

            <div class="third">
                <mat-checkbox formControlName="remove_batch">Remove Batch</mat-checkbox>
            </div>

            <div class="third">
                <mat-checkbox formControlName="remove_rev">Remove Revision</mat-checkbox>
            </div>


            <div class="third">
                <mat-checkbox formControlName="remove_lot">Remove Lot</mat-checkbox>
            </div>


            <mat-form-field class="third">
                <mat-label>New Batch</mat-label>
                <input matInput formControlName="batch" placeholder="keep as is if empty">
            </mat-form-field>

            <mat-form-field class="third">
                <mat-label>New Revision</mat-label>
                <input matInput formControlName="rev" placeholder="keep as is if empty">
            </mat-form-field>


            <mat-form-field class="third">
                <mat-label>New Lot</mat-label>
                <input matInput formControlName="lot" placeholder="keep as is if empty">
            </mat-form-field>


            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!form.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
