import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {Table} from "../../../interfaces/table.interface";
import {HelpersService} from "../../../services/helpers.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "common-table-cell",
    template: `
        <div (click)="col.click ? col.click(rowData): null">
            <ng-template [ngIf]="col.factory" #componentContainer></ng-template>

            <ng-template [ngIf]="!col.factory">
                <div *ngIf="col.render; else renderElse" [innerHTML]="trustHtml(col.render(rowData))"></div>
                <ng-template #renderElse>{{ get_value(rowData, col.data) }}</ng-template>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellComponent implements AfterViewInit, OnDestroy, OnChanges {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public rowData: any;

    @Input()
    public col: Table.ICol;

    @ViewChild("componentContainer", {read: ViewContainerRef, static: false})
    public componentContainer: ViewContainerRef;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private cfr: ComponentFactoryResolver,
        private sanitizer: DomSanitizer,
    ) {
    }

    private renderComponent(): void {
        this.componentContainer.clear();
        const componentFactory: any = this.cfr.resolveComponentFactory(this.col.factory.component);

        const colComponentRef: any = this.componentContainer.createComponent(componentFactory);

        const colComponent: any = colComponentRef.instance;

        for (const propKey of Object.keys(this.col.factory.properties)) {

            const propValue = this.col.factory.properties[propKey];

            if (propValue instanceof TableCellSubscriber) {
                (colComponent[propKey] as EventEmitter<any>)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(($event: any): void => {
                        propValue.emit(this.rowData, $event);
                    });

            } else if (propValue instanceof TableCellRowValue) {
                colComponent[propKey] = propValue.value(this.rowData);
            } else {
                colComponent[propKey] = propValue;
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    public get_value(obj: any, path: string): string {
        const value: string = HelpersService.get_value(obj, path);

        if (Array.isArray(value)) {
            return value.join(", ");
        }

        return value;
    }


    public trustHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    public ngAfterViewInit(): void {
        if (this.col.factory && this.componentContainer) {
            this.renderComponent();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.destroy$.next(true);
        if (changes.col) {
            this.col = changes.col.currentValue;
            if (this.col.factory && this.componentContainer) {
                this.renderComponent();
            }
            this.changeDetectorRef.markForCheck();
        }

        if (changes.rowData) {
            this.rowData = changes.rowData.currentValue;
            this.changeDetectorRef.markForCheck();
        }
    }

}


export class TableCellSubscriber {

    private callback: (rowData: any, event?: any) => any;

    public constructor(callback: ((rowData: any, event?: any) => any)) {
        this.callback = callback;
    }

    public emit(rowData: any, event: any): void {
        this.callback(rowData, event);
    }

}

export class TableCellRowValue {

    private key: string;

    public constructor(key: string) {
        this.key = key;
    }

    public value(rowData: any): string {
        return rowData[this.key];
    }

}
