import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api} from "../../../../../../common/services/api.service";
import {UserService} from "../../../../../../common/services/user.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FormControl, FormGroup} from "@angular/forms";
import {StorageService} from "../../../../../../common/services/storage.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {RemarksSidebarComponent} from "../../../../common/components/order";
import {ModalComponent} from "src/modules/common/components/modal/modal.component";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {OrderCancelModalComponent} from "../../../../partner/components/order";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {CommonFormComponent} from "../../../../../../common/components/form";


@Component({
    selector: "section-order-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">{{ params.title }}</h1>
        <mat-card>
            <div class="filter-plus-content screenHeight">
                <div class="filter-container" [class.open]="showFilter">
                    <div>
                        <form [formGroup]="filters" class="transparent margin-bottom-10">

                            <common-form-date-range
                                label="Date range"
                                [inline]="false"
                                [value]="filters.value.date_range"
                                [show_time]="false"
                                (valueChange)="filters.get('date_range').setValue($event)">
                            </common-form-date-range>

                            <mat-form-field class="filter-by">
                                <mat-label>Filter by</mat-label>
                                <mat-select formControlName="order_action">
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option value="inbound">Inbound</mat-option>
                                    <mat-option value="outbound">Outbound</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <common-form-select
                                class="country-filter"
                                [multiple]="true"
                                [options]="countriesOptions"
                                [selectAll]="false"
                                [search]="true"
                                [selectedOnTop]="false"
                                [value]="filters.value.country_iso_2"
                                (onClose)="filters.get('country_iso_2').setValue($event)"
                                (valueChange)="checkEu($event)"
                                label="Country">
                            </common-form-select>


                            <common-form-hub-select
                                class="country-filter"
                                label="Hub"
                                [multiple]="true"
                                [value]="filters.value.hub_id"
                                (onClose)="filters.get('hub_id').setValue($event)"
                            ></common-form-hub-select>

                            <common-form-select
                                class="country-filter"
                                [multiple]="true"
                                url="/status"
                                [selectAll]="true"
                                [search]="true"
                                [value]="filters.value.order_status_id"
                                (onClose)="filters.get('order_status_id').setValue($event)"
                                label="Order status"></common-form-select>

                            <mat-form-field class="filter-item-status">
                                <mat-label>Items status</mat-label>
                                <mat-select [formControl]="filter_by_items_status"

                                            multiple
                                            (closed)="setItemStatusFilter()">
                                    <mat-optgroup label="Inbound items status">
                                        <mat-option *ngFor="let s of ['Open','In Progress','Exceptions','Closed']"
                                                    [value]="{name: s, type:'inbound'}">
                                            {{ s }}
                                        </mat-option>
                                    </mat-optgroup>

                                    <mat-optgroup label="Outbound items status">
                                        <mat-option *ngFor="let s of ['Open','In Progress','Exceptions','Closed']"
                                                    [value]="{name: s, type:'outbound'}">
                                            {{ s }}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>

                            <common-form-select
                                class="country-filter"
                                url="/order_type"
                                [multiple]="true"
                                [value]="filters.value.order_type_id"
                                (onClose)="filters.get('order_type_id').setValue($event ? $event : null)"
                                label="Order type"></common-form-select>

                            <common-form-select
                                class="country-filter"
                                [multiple]="true"
                                [options]="service_levels"
                                [selectAll]="true"
                                [search]="true"
                                [value]="filters.value.service_level"
                                (onClose)="filters.get('service_level').setValue($event)"
                                label="Service Level">
                            </common-form-select>

                            <mat-checkbox formControlName="show_invalid">Show only invalid</mat-checkbox>
                            &nbsp;
                        </form>
                        <div class="row flex space-between">
                            <div class="half">
                                <common-load-config [configId]="'order-list-'+params.title"
                                                    label="Filter"
                                                    color="accent"
                                                    [config]="filters.value"
                                                    (onClick)="applyFilter()"
                                                    (onConfigSelect)="applyFilter($event)"></common-load-config>
                            </div>

                            <div class="half">
                                <button type="button" mat-raised-button color="primary" (click)="clearFilters()">
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-container">
                    <common-table2 *ngIf="listTable" [settings]="listTable" [scrollHeightValue]="255">
                        <div row1>
                            <button mat-mini-fab (click)="toggleFilter()">
                                <mat-icon title="Filters">filter_list</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="params.actions.add" row1>
                            <a *userAllowed="'add_orders'" routerLink="add" mat-raised-button
                               (click)="amplitudeClick('Add order '+params.title)"
                               color="primary" class="create">Add</a>
                        </div>
                    </common-table2>
                </div>
            </div>
        </mat-card>
        <section-remarks-sidebar #sidebar></section-remarks-sidebar>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private countries: Contact.ICountry[];

    public state: Base.IState;

    @Input()
    public params: Order.IOrderListParams;

    @Output()
    public result: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild("sidebar", {static: true})
    public sidebarRef: RemarksSidebarComponent;

    public showFilter: boolean = false;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public filter_by_items_status: FormControl = new FormControl([]);

    public filters: FormGroup = new FormGroup({
        date_range: new FormControl(null),
        order_action: new FormControl(null),
        country_iso_2: new FormControl([]),
        hub_id: new FormControl([]),
        inbound_items_status: new FormControl([]),
        outbound_items_status: new FormControl([]),
        show_invalid: new FormControl(false),
        order_status_id: new FormControl([]),
        order_type_id: new FormControl([]),
        service_level: new FormControl([]),
    });

    public showExportButton: boolean = false;

    public service_levels: ISelectOption[] = [];

    public countriesOptions: ISelectOption[] = [];

    public constructor(
        private router: Router,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private storageService: StorageService,
        public userService: UserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getUrl(): any {
        return {
            url: [this.state.section, "orders"],
            query: {
                ...this.params.query,
                ...this.filters.value,
                open_only: this.params.state.params.only_open ? true : null,
                with_count: [
                    "Followups",
                    "OpenFollowups",
                    "OrderRemarks"
                ],
                relations: [
                    "status",
                    "ServiceLevel",
                    "ServiceLevel.orderType:id,slug,display_name",
                    "ServiceLevel.shippingMode",
                    "partner:id,slug,icon_path",
                    "MainAddress.customer",
                    "OutboundShipment:id,order_id",
                    "OutboundShipment.CourierTransaction:id,shipment_id,status,delivered_at",
                    "InboundShipment.CourierTransaction:id,shipment_id,status,delivered_at",
                    "InboundWarehouseTransaction:id,order_id,created_at",
                    "CreatedBy:id,name",
                    "InboundOrderItems:id,order_id,inventory_conversion_id",
                    "OutboundOrderItems:id,order_id,inventory_conversion_id",
                    "InboundOrderItems.InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                    "OutboundOrderItems.InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                    "ServiceRequest",
                ]
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];

        if (this.params.actions.remarks
            && this.userService.validatePermissions("browse_order_remarks")) {
            actions.push({
                name: "remarks",
                title: "Remarks",
                click: async (row: any): Promise<any> => {
                    this.sidebarRef.show(row.id, row.ref, true);
                    AmplitudeService.eventClick("Remarks");
                },
                cssClassFn: (row: any): string => {
                    return row.order_remarks_count ? "mat-primary" : "";
                }
            });
        }

        if (this.userService.validatePermissions("can_see_shift")) {
            actions.push({
                name: "update",
                title: "Followups",
                click: async (row: any): Promise<any> => {
                    const link: string = `/${(this.params.state.section === "admin"
                        ? "/partner/" + row.partner.slug
                        : this.params.state.section)}/followups-all/search/order/${row.id}`;

                    window.open(link);

                },
                cssClassFn: (row: any): string => {
                    return row.followups_count ? "mat-accent" : "";
                },
                badgeFn: (row: any): string => {
                    return row.open_followups_count > 0 ? row.open_followups_count : null;
                }
            });
        }

        actions.push({
            name: "flag",
            title: "Priority",
            click: async (row: any): Promise<any> => {
                const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
                    formConfig: {
                        name: "Select priority level",
                        description: "",
                        fields: [
                            {
                                label: "Priority",
                                name: "priority",
                                size: "full",
                                type: "select",
                                required: true,
                                values: [
                                    "Low",
                                    "Normal",
                                    "High",
                                    "Highest"
                                ]
                            },
                        ],

                    },
                    values: {
                        priority: Order.PRIORITY_LEVELS[row.priority]
                    },
                });

                if (res?.value) {
                    const {code, message}: Api.IResponse = await this.api3Service.put(
                        `${this.state.section}/orders/${row.id}`,
                        {
                            priority: Order.PRIORITY_LEVELS.indexOf(res.value.priority)
                        }
                    );

                    if (code === 200) {
                        this.tableRef.reload();
                    }
                }


            },
            cssClassFn: (row: any): string => {
                return "priority-color-" + row.priority;
            }
        });

        actions.push({
            name: "delete",
            title: "Delete",
            click: async (row: any): Promise<any> => {
                AmplitudeService.eventClick("Delete " + this.params.title, {order: row.ref});

                await this.modalService.open(OrderCancelModalComponent, {
                    orderData: row,
                    softCancel: false,
                    state: this.state
                });

                this.tableRef.reload();

            },
            disabledFn: (row: any): boolean => {
                return !this.params.actions.delete
                    || !this.userService.validatePermissions("delete_orders")
                    || row.confirmed;
            }
        });

        const columns: Table.ICol[] = [
            {
                data: "ref",
                title: "Order",
                render: (row: any): string => {
                    if (this.params.actions.view && this.userService.validatePermissions("read_orders")) {
                        return "<button class='mat-mdc-raised-button mdc-button button-200' " +
                            "type='button' data-action='view'>"
                            + row.ref
                            + "</button>";
                    }
                    return row.ref;
                },
                click: (row: any): void => {
                    const type: string = (row.service_level && row.service_level.order_type) &&
                        (row.service_level.order_type.slug || "");
                    if (this.params.state) {
                        this.router.navigate([
                            (this.params.state.section === "admin"
                                ? "/partner/" + row.partner.slug
                                : this.params.state.section),
                            "orders",
                            "view",
                            "id",
                            row.id
                        ]);
                        AmplitudeService.eventClick("View " + this.params.title, {order: row.ref});
                    }
                }
            },
            {
                data: "id",
                title: "",
                render: (row: any): string => {
                    if (row.id) {
                        return `<mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                            open_in_new
                            </mat-icon>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.id) {
                        window.open("/" + this.state.section + "/orders/view/id/" + row.id, "_blank");
                    }
                },
                cssClass: "action max",
                sortable: false,
                searchable: false
            },
            {
                data: "ref2",
                title: "ref2"
            },
            {
                data: "service_request.ref",
                name: "ServiceRequest.ref",
                title: "Service request",
                sortable: false,
                render: (row: any): string => {
                    if (row.service_request?.ref?.length > 30) {
                        return "<span title='" + row.service_request.ref.replace("'", "`") + "' class='pointer'>"
                            + row.service_request.ref.substring(0, 30) + "...</span>";
                    } else if (row.service_request?.ref) {
                        return row.service_request.ref;
                    }
                    return "";
                },
            },
            {
                data: "inbound_order_items.inventory_conversion.customers_inventory_name",
                name: "InboundOrderItems.InventoryConversion.customers_inventory_name",
                title: "Hub inbound",
                sortable: false,
                render: (row: any): string => {
                    if (!row.inbound_order_items || row.inbound_order_items.length == 0) {
                        return "";
                    }
                    if (this.hasSeveralHubs(row.inbound_order_items)) {
                        return "<button type='button' class='mat-mdc-raised-button mdc-button button-200'>" +
                            "Show hubs</button>";
                    }
                    return row.inbound_order_items[0].inventory_conversion.customers_inventory_full_name;
                },
                click: (row: any): void => {
                    if (!this.hasSeveralHubs(row.inbound_order_items)) {
                        return;
                    }
                    this.showHubs(row.inbound_order_items);
                },
            },
            {
                data: "outbound_order_items.inventory_conversion.customers_inventory_name",
                name: "OutboundOrderItems.InventoryConversion.customers_inventory_name",
                title: "Hub outbound",
                sortable: false,
                render: (row: any): string => {
                    if (!row.outbound_order_items || row.outbound_order_items.length == 0) {
                        return "";
                    }
                    if (this.hasSeveralHubs(row.outbound_order_items)) {
                        return "<button type='button' class='mat-mdc-raised-button mdc-button button-200'>" +
                            "Show hubs</button>";
                    }
                    return row.outbound_order_items[0].inventory_conversion.customers_inventory_full_name;
                },
                click: (row: any): void => {
                    if (!this.hasSeveralHubs(row.outbound_order_items)) {
                        return;
                    }
                    this.showHubs(row.outbound_order_items);
                }
            },
            {
                data: "status.name",
                name: "status.name",
                title: "Status",
                sortable: false
            },
            {
                data: "outbound_shipment.courier_transaction.status",
                name: "OutboundShipment.CourierTransaction.status",
                title: "Shipment status",
                sortable: false,
                searchable: false,
                render: (row: any): string => {
                    if (row.service_level.order_type.id === 2) {
                        //replenishment
                        return row.inbound_shipment?.courier_transaction?.status || "";
                    } else {
                        return row.outbound_shipment?.courier_transaction?.status || "";
                    }
                }
            },
            {
                data: "service_level.name",
                name: "ServiceLevel.name",
                title: "Service level",
                sortable: false
            },
            {
                data: "service_level.order_type.display_name",
                name: "ServiceLevel.orderType.display_name",
                title: "Order type",
                sortable: false
            },
            {
                data: "main_address.customer.name",
                name: "MainAddress.customer.name",
                title: "Customer",
                sortable: false
            },
            {
                data: "main_address.country",
                name: "MainAddress.country",
                title: "Country",
                render: (row: any): string => {
                    return row.main_address && row.main_address.country_iso_2
                        ? "<img class='flag' src='assets/images/flags/"
                        + row.main_address.country_iso_2.toLowerCase().trim() + ".svg' "
                        + "alt='" + row.main_address.country + "' title='" + row.main_address.country + "'>" : "";
                },
                sortable: false
            },
            {
                data: "main_address.id",
                name: "MainAddress.id",
                title: "Address ID",
                sortable: false
            },
            {
                data: "created_by.name",
                name: "CreatedBy.name",
                title: "Created By",
                render: (row: any): string => {
                    return row.created_by ? row.created_by.name : "System";
                },
                sortable: false
            },
            {
                data: "created_at",
                title: "Created at",
                render: (row: any): string => {
                    return HelpersService.dateTimeFormat(row.created_at);
                }
            },
            {
                data: "deliver_on",
                title: "Requested delivery date",
                searchable: false,
                render: (row: any): string => {
                    if (row.deliver_on) {
                        return HelpersService.dateTimeFormat(row.deliver_on);
                    }
                    return "";
                }
            },
            {
                data: "inbound_warehouse_transaction.created_at",
                name: "InboundWarehouseTransaction.created_at",
                title: "Inbound date",
                sortable: false,
                searchable: false,
                render: (row: any): string => {
                    if (row.inbound_warehouse_transaction && row.inbound_warehouse_transaction.created_at) {
                        return HelpersService.dateTimeFormat(row.inbound_warehouse_transaction.created_at);
                    }
                    return "";
                }
            },
            {
                data: "outbound_shipment.courier_transaction.delivered_at",
                name: "OutboundShipment.CourierTransaction.delivered_at",
                title: "Delivered at",
                sortable: false,
                searchable: false,
                render: (row: any): string => {
                    if (row.outbound_shipment
                        && row.outbound_shipment.courier_transaction
                        && row.outbound_shipment.courier_transaction.delivered_at) {
                        return HelpersService.dateTimeFormat(row.outbound_shipment.courier_transaction.delivered_at);
                    }
                    return "";
                }
            },
        ];

        if (this.params.state.section === "admin") {
            columns.unshift({
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    if (row.partner?.icon_path) {
                        return "<img src='" + row.partner.icon_path + "' alt=''>";
                    }
                    return "";
                },
                sortable: false,
                searchable: false
            });
        }

        let exportParams: Table.IExportSettings = null;
        if (this.userService.validatePermissions("browse_reports")) {
            exportParams = {
                file_name: this.params.title,
                columns: [
                    {
                        data: "ref",
                        title: "Ref"
                    },
                    {
                        data: "ref2",
                        title: "Ref2"
                    },
                    {
                        data: "InboundOrderItems.InventoryConversion.customers_inventory_full_name",
                        title: "Inbound hub"
                    },
                    {
                        data: "OutboundOrderItems.InventoryConversion.customers_inventory_full_name",
                        title: "Outbound hub"
                    },
                    {
                        data: "Status.name",
                        title: "Status"
                    },
                    {
                        data: "ServiceLevel.name",
                        title: "Service level"
                    },
                    {
                        data: "ServiceLevel.OrderType.display_name",
                        title: "Order type"
                    },
                    {
                        data: "MainAddress.customer.name",
                        title: "Customer"
                    },
                    {
                        data: "MainAddress.country",
                        title: "Country"
                    },
                    {
                        data: "MainAddress.id",
                        title: "Address ID",
                    },
                    {
                        data: "CreatedBy.name",
                        title: "Created by"
                    },
                    {
                        data: "created_at",
                        title: "Created at"
                    }
                ],
            };
        }

        this.listTable = {
            actions,
            api: this.getUrl(),
            columns,
            rowClass: (row: any): string => {
                if (!row.confirmed) {
                    return "mat-accent";
                }
                if (row.status && row.status.id === 4) {
                    return "mat-danger";
                }
                return "";
            },
            export: exportParams,
            infinity_scroll: true,
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getCountries(): Promise<any> {

        const {data}: Api.IResponse = await this.api3Service.get(
            `${AppStateService.getState().section}/countries`);

        if (data) {
            this.countries = data;
            this.setAllCountriesOptions();
        }
    }

    private setAllCountriesOptions(): void {
        this.countriesOptions = this.countries.map((c: Contact.ICountry) => {
            return {value: c.country_iso_2, name: c.country_name};
        });
        this.countriesOptions.unshift({value: "in_eu", name: "EU Countries", cssClass: "bold border-bottom"});
        this.changeDetectorRef.markForCheck();
    }

    private setEuCountriesOptions(): void {
        this.countriesOptions = this.countries.map((c: Contact.ICountry) => {
            return {value: c.country_iso_2, name: c.country_name, disabled: !c.in_eu};
        });
        this.countriesOptions.unshift({value: "in_eu", name: "EU Countries", cssClass: "bold border-bottom"});
        this.changeDetectorRef.markForCheck();
    }

    private async getServiceLevels(): Promise<any> {
        const relations = [];

        if (this.state.section === "admin") {
            relations.push("Partner:id,display_name");
        }

        const {data}: Api.IResponse = await this.api3Service.get(
            `${AppStateService.getState().section}/service-levels`, {
                relations
            });

        if (data) {
            this.service_levels = data.map((service_level: Order.IServiceLevel): ISelectOption => {

                return {
                    value: service_level.id,
                    name: service_level.name
                        + (this.state.section === "admin" ? " - <" + service_level.partner?.display_name + ">" : "")
                };
            });
        }

    }

    private getDistinctHubsNamesByOrderItems(items: any): never[] {
        let hubsNames = items.map((item: any) => item.inventory_conversion.customers_inventory_full_name);
        return Array.from(new Set(hubsNames));
    }

    public setItemStatusFilter(): void {
        const inbound: string[] = [];
        const outbound: string[] = [];
        for (const val of this.filter_by_items_status.value) {
            if (val.type === "inbound") {
                inbound.push(val.name);
            } else {
                outbound.push(val.name);
            }
        }

        this.filters.get("inbound_items_status").setValue(inbound, {emitEvent: false});
        this.filters.get("outbound_items_status").setValue(outbound);
    }

    public async showHubs(items: any): Promise<any> {
        const distinctHubsNames = this.getDistinctHubsNamesByOrderItems(items);
        await this.modalService.open(ModalComponent, {
            title: "Hubs",
            template: distinctHubsNames.join("<br>")
        });
    }

    public hasSeveralHubs(items: any): boolean {
        const distinctHubsNames = this.getDistinctHubsNamesByOrderItems(items);
        return !(!distinctHubsNames || distinctHubsNames.length < 2);
    }

    public toggleFilter(): void {
        this.storageService.set("showFilterOrder", !this.showFilter);
        this.showFilter = this.storageService.get("showFilterOrder");
    }

    public amplitudeClick(message: string, properties: any = {}): void {
        AmplitudeService.eventClick(message, properties);
    }

    public applyFilter(event = null): void {
        if (event) {
            this.filters.patchValue(event);
        }
        this.tableRef.reload(this.getUrl());
    }

    public checkEu(event): void {
        if (event[0] && event[0] === "in_eu") {
            const eu: string[] = this.countries.filter((c: Contact.ICountry) => {
                return c.in_eu;
            })
                .map((c: Contact.ICountry) => c.country_iso_2);

            eu.unshift("in_eu");
            this.filters.get("country_iso_2").setValue(eu, {emitEvent: false});

            this.setEuCountriesOptions();
        } else if (event[0] && event[0] !== "in_eu" && this.filters.value.country_iso_2[0] === "in_eu") {
            this.filters.get("country_iso_2").setValue([]);
            this.setAllCountriesOptions();
        }
    }

    public ngOnInit(): void {
        this.state = AppStateService.getState();

        this.changeDetectorRef.markForCheck();

        this.prepareList();
        this.getServiceLevels();
        this.getCountries();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public clearFilters(): void {
        this.filters.reset();
        this.filter_by_items_status.reset();
        AmplitudeService.eventClick("Clear filters " + this.params.title);
        this.tableRef.reload(this.getUrl());
    }
}
