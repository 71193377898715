import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef, OnDestroy, EventEmitter
} from "@angular/core";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {Modal} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-order-item-sticker",
    template: `
        <div class="pull-right">
            <mat-slide-toggle [formControl]="multiple">
                Print multiple
            </mat-slide-toggle>
            &nbsp;
            &nbsp;
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
        <h1 class="container-heading">Item sticker</h1>
        <mat-card>
            <mat-card-content #sticker>
                <div class="sticker" *ngFor="let item of items; let i = index"
                     [style.width]="partner && partner.slug==='modix'?'120mm':'100mm'">
                    <table>

                        <tr *ngIf="partner && partner.logo_path" class="logo">
                            <td>
                                <img [src]="partner.logo_path" alt="" class="logo">
                            </td>

                            <td class="text-right">
                                <div>Qty: <b>{{ item.quantity }}</b></div>
                                <!--<common-barcode [bcValue]="item.quantity"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                [bcDisplayValue]="false"
                                                bcHeight="30"></common-barcode>-->
                            </td>
                        </tr>

                        <tr class="item-qty">
                            <td class="text-center" colspan="2">
                                <div>Item: <b>{{ item.item }}</b></div>
                                <common-barcode [bcValue]="clearValue(item.item)"
                                                [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>

                                <div *ngIf="item.inventory_conversion" style="margin-bottom: 5px">
                                    {{item.inventory_conversion?.customers_inventory_name}}
                                    {{item.inventory_conversion?.customers_sub_inventory}}
                                </div>

                                <div *ngIf="item.part_master?.description" style="word-break: break-all;">
                                    <b>Description:</b><br>{{ item.part_master.description }}
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="partner && partner.properties.show_order_ref_on_item_sticker && item.order_ref">
                            <td colspan="2">
                                <div class="text-center ref"><small>Order ref:</small> {{ item.order_ref }}</div>
                            </td>
                        </tr>

                        <tr class="serial-configurations">
                            <td>
                                <ng-template
                                    [ngIf]="item.serial
                                        || (items.length === 1 && serials?.length === 1 && serials[i])
                                        || (items.length > 1 && serials?.length > 1 && serials[i])">
                                    <div>SN: <b>{{ item.serial ?? serials[i] }}</b></div>
                                    <common-barcode *ngIf="item.serial ?? serials[i]"
                                                    [bcValue]="clearValue(item.serial ?? serials[i])"
                                                    [bcDisplayValue]="false"
                                                    [bcMarginTop]="1"
                                                    [bcMarginLeft]="1"
                                                    [bcMarginRight]="1"
                                                    [bcMarginBottom]="1"
                                                    bcWidth="1"
                                                    bcHeight="20"></common-barcode>
                                </ng-template>
                            </td>

                            <td>
                                <ng-template [ngIf]="getConfigurationNames(item.configurations) &&
                                getConfigurationNames(item.configurations).length <= 25">
                                    <div>Conf: <b>{{ getConfigurationNames(item.configurations) }}</b></div>
                                    <common-barcode [bcValue]="getConfigurationNames(item.configurations)"
                                                    [bcDisplayValue]="false"
                                                    [bcMarginTop]="1"
                                                    [bcMarginLeft]="1"
                                                    [bcMarginRight]="1"
                                                    [bcMarginBottom]="1"
                                                    bcWidth="1"
                                                    bcHeight="20"></common-barcode>
                                </ng-template>
                                <ng-template [ngIf]="getConfigurationNames(item.configurations) &&
                                getConfigurationNames(item.configurations).length > 25">
                                    <div>This item has configurations</div>
                                </ng-template>
                            </td>
                        </tr>

                        <tr class="batch-rev">
                            <td>
                                <ng-template [ngIf]="item.batch">
                                    <div>Batch: <b>{{ item.batch }}</b></div>
                                    <common-barcode [bcValue]="clearValue(item.batch)"
                                                    [bcDisplayValue]="false"
                                                    [bcMarginTop]="1"
                                                    [bcMarginLeft]="1"
                                                    [bcMarginRight]="1"
                                                    [bcMarginBottom]="1"
                                                    bcWidth="1"
                                                    bcHeight="20"></common-barcode>
                                </ng-template>
                            </td>
                            <td>
                                <ng-template [ngIf]="item.rev">
                                    <div>Rev: <b>{{ item.rev }}</b></div>
                                    <common-barcode [bcValue]="clearValue(item.rev)"
                                                    [bcDisplayValue]="false"
                                                    [bcMarginTop]="1"
                                                    [bcMarginLeft]="1"
                                                    [bcMarginRight]="1"
                                                    [bcMarginBottom]="1"
                                                    bcWidth="1"
                                                    bcHeight="20"></common-barcode>
                                </ng-template>
                            </td>
                        </tr>

                        <tr *ngIf="item.location">
                            <td colspan="2">
                                <div class="text-center">
                                    Location: {{ item.location }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemStickerComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private item: any;

    public items: any[] = [];

    public modal: Modal.IModal;

    public partner: User.IPartner;

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    public serials = [];

    public locations = [];

    public multiple: FormControl = new FormControl(false);

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private buildSingle(): void {
        this.items = [];
        if (this.modal.params.locations) {

            let qty = this.item.quantity;

            for (const location of this.modal.params.locations) {
                const item = {...this.item};
                item.quantity = location.quantity;
                item.location = location.location;
                this.items.push(item);

                qty -= location.quantity;
            }

            if (qty > 0) {
                const item = {...this.item};
                item.quantity = qty;
                this.items.push(item);
            }
        } else {
            this.items = [this.item];
        }
    }

    private buildMultiple(): void {
        this.items = [];

        let qty = this.item.quantity;

        if (this.modal.params.locations) {
            for (const location of this.modal.params.locations) {
                for (let i = 0; i < location.quantity; i++) {
                    const item = {...this.item};
                    item.quantity = 1;
                    item.location = location.location;
                    this.items.push(item);

                    qty--;
                }
            }
        }

        for (let i = 0; i < qty; i++) {
            const item = {...this.item};
            item.quantity = 1;
            this.items.push(item);
        }


        this.changeDetectorRef.markForCheck();
    }


    private async getPartner(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "" + this.modal.params.item.partner_id]);
        if (data) {
            this.partner = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public getConfigurationNames(configurations: { name: string }[] | string): string {
        return Array.isArray(configurations)
            ? (configurations.map((conf: { name: string }): string => conf.name)).join(", ")
            : configurations;
    }

    public print(): void {
        setTimeout(() => {
            const printContents: any = this.sticker.nativeElement.innerHTML;
            HelpersService.stickerPopUp(printContents);
        }, 100);
    }

    public clearValue(str: string): string {
        return str.trim();
    }


    public ngOnInit(): void {

        this.item = {...this.modal.params.item};

        this.serials = this.modal.params.serials ?? [];
        this.getPartner();
        this.buildSingle();

        this.multiple.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((multiple: boolean) => {
            if (multiple) {
                this.buildMultiple();
            } else {
                this.buildSingle();
            }
        });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
