import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    EventEmitter,
    OnDestroy
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Table2Component} from "src/modules/common/components/table2";
import {Base} from "src/modules/common/interfaces/base.interfaces";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Api, ApiService} from "src/modules/common/services/api.service";
import {Api3Service} from "src/modules/common/services/api3.service";
import {PartnerService} from "src/modules/common/services/partner.service";
import {Modal, ModalService} from "src/modules/section/services/modal.service";
import {PackagePalletEditComponent} from "../form/form.component";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {FormControl} from "@angular/forms";
import {takeUntil, distinctUntilChanged, debounceTime} from "rxjs";
import {Warehouse} from "src/modules/common/interfaces/warehouse.interface";
import {HelpersService} from "src/modules/common/services/helpers.service";
import {SpinnerService} from "src/modules/common/services/spinner.service";

@Component({
    selector: "partner-packages-pallets-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Pallets List</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                <div row1 class="flex row align-center">
                    <common-form-select *ngIf="hubList?.length"
                        [options]="hubList"
                        [multiple]="true"
                        [selectAll]="true"
                        [search]="true"
                        (onClose)="hubSelect.setValue($event)"
                        class="margin-right-10"
                        style="width: 250px"
                        label="Hubs"></common-form-select>

                    <common-autocomplete *ngIf="countries?.length"
                        [options]="countries" placeholder="Country" [allowFreeInput]="false"
                        class="margin-right-10" style="width: 250px"
                        (optionSelected)="countrySelect.setValue($event.value)"
                        [errorText]="'Select country'" [required]="false"
                        [image]="{path:'assets/images/flags',ext:'svg'}"
                        [value]="countrySelect.value"></common-autocomplete>
                </div>
                    <div data-actions>
                        <a mat-raised-button color="primary" (click)="addPallet()">
                            Add New
                        </a>
                        <a mat-raised-button color="accent" (click)="upload()">
                            Upload CSV
                        </a>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesPalletsListComponent implements OnInit, OnDestroy, Base.IComponent {
    public state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public hubList: { name: string, value: any, name2: string }[];
        
        public hubSelect: FormControl = new FormControl([]);
    
        public countries: any[] = [];
        
        public countrySelect: FormControl = new FormControl(null);
    

    public constructor(
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiV3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private apiService: ApiService,
        private helpers: HelpersService,
    ) {

    }

    private get url(): Table.ITableApi {
        return {
            url: ["partner", PartnerService.partner.slug, "pallets"],
            query: {
                hubs: this.hubSelect.value,
                country: this.countrySelect.value,
                relations: ["WarehouseLocation", "Boxes", "Inventory.InventoryConversion"],
                counts: [
                    "Boxes",
                ],
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "partner_packages_pallets_list",
            actions: [
                {
                    name: "delete",
                    title: "",
                    icon: "delete",
                    click: (row: any) => {
                        this.delete(row.id);
                    },
                    disabledFn: (row: any) => {
                        if (!row.boxes.length) {
                            return false;
                        }
                        return row.boxes.findIndex(box =>
                            Number(box.warehouse_transactions_count) > 0
                        ) != -1;
                    },
                },
                {
                    name: "recalculate",
                    title: "Recalculate quantity",
                    icon: "sync",
                    click: (row: any) => {
                        this.apiV3Service.post(`${this.state.section}/pallets/${row.id}/recalculate-inventory`)
                            .then(({code, message}: Api.IResponse): void => {
                                if (code === 200) {
                                    this.toastService.show(message, "success");
                                }
                            });
                    },
                }
            ],
            columns: [
                {
                    "data": "ref",
                    "title": "Ref",
                },
                {
                    "data": "inventory.inventory_conversion.customers_inventory_name",
                    "name": "Inventory.InventoryConversion.customers_inventory_name",
                    "title": "Hub (from Inventory)",
                    "render": (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    }
                },
                {
                    "data": "warehouse_location.location",
                    "name": "WarehouseLocation.location",
                    "title": "Warehouse Location"
                },
                {
                    "data": "boxes_count",
                    "name": "boxes_count",
                    "title": "Boxes"
                }
            ],
            api: this.url
        };

        this.changeDetectorRef.markForCheck();
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    name2: `<b>${hub.customers_inventory_name}</b>/${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }
    
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public async addPallet(): Promise<void> {
        const response: Modal.IResponse = await this.modalService.open(PackagePalletEditComponent, {
            add: true
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "pallet",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public async delete(id: number): Promise<void> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Pallet?")) {
            return;
        }
        const response = await this.apiV3Service.request(Api.EMethod.Delete,
            `partner/${PartnerService.partner.slug}/pallets/${id}`
        );
        if (response) {
            this.tableRef.reload();
        }
    }

    ngOnInit(): void {
        this.prepareList();

        this.getHubList();
        this.prepareCountriesList();
        
        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            }
        );

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                if (this.countries.findIndex((country: any) => {
                    return country === value;
                })) {
                    await this.getHubList(value);
                    this.tableRef.reload(this.url);
                }
            }
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "packages-pallets-list",
            actions: {
                "browse": ["browse_part_masters"]
            }
        };
    }
}