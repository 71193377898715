import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {Api3Service} from "./api3.service";
import {AppStateService} from "./app-state.service";


@Injectable()
export class PartMasterService {

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service
    ) {
    }

    public getPartMasters(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster"], {}, query);
    }

    public deletePartMasterFile(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Delete, ["partmaster", "files", "" + id]);
    }

    public getPartMaster(item: string, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster", item], {}, query);
    }

    public createPartMaster(body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post,
            `/${AppStateService.getState().section}/part-masters`, body, query);
    }

    public updatePartMaster(id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put,
            `/${AppStateService.getState().section}/part-masters/${id}`, body, query);
    }


    public getSerial(serial: string, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["serial", serial], {}, query);
    }

    public updateSerial(serial: string, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["serial", serial], body, query);
    }

    public getPartMasterUoms(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster", "uom"], {}, query);
    }

    public getPartMasterNotes(part_master_id: string): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Get,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/notes`
        );
    }

    public createPartMasterNote(part_master_id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/notes`, body, query);
    }

    public updatePartMasterNote(part_master_id: number, note_id: number, body: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/notes/${note_id}`, body);
    }

    public deletePartMasterNote(part_master_id: number, note_id: number): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Delete,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/notes/${note_id}`);
    }

    public getSerialNotes(serial_id: string): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Get,
            `/${AppStateService.getState().section}/serials/${serial_id}/notes`
        );
    }

    public createSerialNote(serial_id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post,
            `/${AppStateService.getState().section}/serials/${serial_id}/notes`, body, query);
    }

    public updateSerialNote(serial_id: number, note_id: number, body: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put,
            `/${AppStateService.getState().section}/serials/${serial_id}/notes/${note_id}`, body);
    }

    public deleteSerialNote(serial_id: number, note_id: number): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Delete,
            `/${AppStateService.getState().section}/serials/${serial_id}/notes/${note_id}`);
    }

    public getPartMasterMinQuantity(part_master_id: string): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Get,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/min-qty`
        );
    }

    public createPartMasterMinQty(part_master_id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/min-qty`, body, query);
    }

    public updatePartMasterMinQty(part_master_id: number, minQtyId: number, body: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/min-qty/${minQtyId}`, body);
    }

    public deletePartMasterMinQty(part_master_id: number, minQtyId: number): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Delete,
            `/${AppStateService.getState().section}/part-masters/${part_master_id}/min-qty/${minQtyId}`);
    }
}
