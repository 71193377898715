import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {
    AbstractWizardComponent,
    Wizard,
    WizardStepFactory
} from "../../../../../../../common/interfaces/wizard.interface";
import {RemarksSidebarComponent} from "../../../../../common/components/order";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {
    WarehouseProceduresWizardScanTypeComponent,
    WarehouseProceduresWizardTransferHubToHubComponent,
    WarehouseProceduresWizardTransferLocationToLocationComponent,
    WarehouseProceduresWizardTransferUpdateBoxComponent,
    WarehouseProceduresWizardTransferUpdatePalletComponent,
    WarehouseProceduresWizardTransferUpdateSerialHubComponent,
    WarehouseProceduresWizardTransferUpdateSerialLocationComponent
} from "../../index";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../common/services/user.service";

@Component({
    selector: "section-procedures-transfer",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "../common/wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class WarehouseProceduresWizardTransferComponent extends AbstractWizardComponent
    implements OnInit, AfterViewInit {

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    public status: any;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private userService: UserService,
    ) {
        super(changeDetectorRef);
    }

    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {
        if (this.steps[stepIndex].title === "Scan type") {
            if (event.value === "box-location" || event.value === "box-hub") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Scan Box", WarehouseProceduresWizardTransferUpdateBoxComponent));
            } else if (event.value === "serial-hub") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Select serial",
                        WarehouseProceduresWizardTransferUpdateSerialHubComponent));
            } else if (event.value === "serial-location") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Select serial",
                        WarehouseProceduresWizardTransferUpdateSerialLocationComponent));
            } else if (event.value === "location-location") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Select locations",
                        WarehouseProceduresWizardTransferLocationToLocationComponent));
            } else if (event.value === "inventory-location") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Select locations and inventory",
                        WarehouseProceduresWizardTransferLocationToLocationComponent));
            } else if (event.value === "inventory-hub") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Select hubs and inventory",
                        WarehouseProceduresWizardTransferHubToHubComponent));
            } else if (event.value === "pallet-location" || event.value === "pallet-hub") {
                this.steps.splice(1);
                this.steps.push(
                    new WizardStepFactory("Scan pallet", WarehouseProceduresWizardTransferUpdatePalletComponent));
            }
        }
    }

    public async getData(): Promise<any> {
        this.spinnerService.show();
        const [type, slug]: string[] = this.state.section.split("/");
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", slug]);
        if (data) {
            this.data.warehouse = data;
            this.data.scannerKey = this.data.warehouse.properties.scanner_key;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    public ngOnInit(): void {
        super.ngOnInit();
        this.data.wizardType = "transfer";

        const options: any = [
            {name: "Update pallet location", value: "pallet-location", default: true},
            {name: "Update box location", value: "box-location", default: false},
            {name: "Update serial location", value: "serial-location", default: false},
            // {name: "Update serial sub inventory", value: "serial-hub", default: false},
            {name: "From location to location", value: "location-location", default: false},
            {name: "Update inventory location", value: "inventory-location", default: false},

        ];

        if (this.userService.validatePermissions("browse_admin")) {
            options.push({name: "Update sub-inventory", value: "inventory-hub", default: false});
        }


        this.steps.push(
            new WizardStepFactory("Scan type", WarehouseProceduresWizardScanTypeComponent,
                false, null, null, {options, template: "tiles"}
            ));

        this.getData();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "procedures-transfer",
            actions: {
                "browse": ["read_reports"],
                "wizard": ["read_reports"],
            }
        };
    }

}
